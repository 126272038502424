<script setup>
import { useCommonStore } from '@/stores/common.js'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

const { notifyType, showNotify, notifyMessage } = storeToRefs(useCommonStore())
const closing = ref(false)
</script>

<template>
  <transition @beforeLeave="closing = true" @afterLeave="closing = false" name="fade">
    <div
      v-show="showNotify"
      class="notify-wrapper relative flex justify-center z-[90000] fixed"
      :class="{ animate__fadeIn: !closing, animate__fadeOut: closing }"
    >
      <div class="notify" :class="notifyType">
        {{ notifyMessage }}
      </div>
    </div>
  </transition>
</template>

<style scoped>
.notify-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90000;
}
.notify {
  position: fixed;
  width: 500px;
  margin-top: 20px;
  max-width: 400px;
  z-index: 90000;
  top: 0;
  padding: 15px 20px;
  flex-grow: 1;
  flex-wrap: wrap;
  font-size: 0.875rem;
  align-items: center;
  font-family: system-ui, 'MS GothicNeo', 'MS Gothic', 'Work Sans', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  box-shadow:
    0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #ffffff;
}
.error {
  background-color: #d32f2f;
}
.success {
  background-color: #8db40c;
}
</style>
