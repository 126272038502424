import { createRouter, createWebHistory } from 'vue-router'
import { ref } from 'vue'
import { getToken } from '@/utils/token.js'
import { useCommonStore } from '@/stores/common.js'
import { useConfigStore } from '@/stores/config.js'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        withoutLogin: true,
      },
      component: () => import('@/views/HomeView.vue'),
    },
    {
      path: '/register',
      name: 'register',
      meta: {
        withoutLogin: true,
      },
      component: () => import('@/views/RegisterView.vue'),
    },
    {
      path: '/register2',
      name: 'register2',
      component: () => import('@/views/RegisterViewMustLogin.vue'),
    },
    {
      path: '/bti-sports',
      name: 'bti-sports',
      meta: {
        auth: true,
        providerId: 30008,
        gameId: 0,
      },
      component: () => import('@/views/IFrameView.vue'),
    },
    {
      path: '/bt1-sports',
      name: 'bt1-sports',
      meta: {
        providerId: 30009,
        gameId: 0,
      },
      component: () => import('@/views/IFrameView.vue'),
    },
    // {
    //     path: '/betconstruct',
    //     name: 'betconstruct',
    //     meta: {
    //         url: '',
    //     },
    //     component: () => import('@/views/IFrameView.vue'),
    // },
    {
      path: '/live-casino',
      name: 'live-casino',
      component: () => import('@/views/LiveCasino.vue'),
    },
    {
      path: '/slots',
      name: 'slots',
      component: () => import('@/views/SlotsView.vue'),
    },
    {
      path: '/game/:providerId/:gameId',
      name: 'gameIFrame',
      component: () => import('@/views/GameIFrameView.vue'),
    },
    // {
    //     path: '/live-hold',
    //     name: 'live-hold',
    //     component: () => import('@/views/JumpView.vue'),
    // },
    {
      path: '/vip',
      name: 'vip',
      component: () => import('@/views/VIP.vue'),
    },
    {
      path: '/promotions',
      name: 'promotions',
      meta: {
        withoutLogin: true,
      },
      component: () => import('@/views/PromotionView/PromotionView.vue'),
    },
    {
      path: '/sports-betting-game-rules',
      name: 'sports-betting-game-rules',
      meta: {
        withoutLogin: true,
      },
      component: () => import('@/views/RuleView/RuleView.vue'),
    },
    {
      path: '/myaccount/deposit',
      name: 'deposit',
      component: () => import('@/views/MyAccount/Deposit/Deposit.vue'),
    },
    {
      path: '/myaccount/withdrawal',
      name: 'withdrawal',
      component: () => import('@/views/MyAccount/Withdraw/Withdrawal.vue'),
    },
    {
      path: '/myaccount/records',
      name: 'records',
      component: () => import('@/views/MyAccount/Records/Records.vue'),
    },
    {
      path: '/myaccount/profile',
      name: 'profile',
      component: () => import('@/views/MyAccount/Profile.vue'),
    },
    {
      path: '/myaccount/notices',
      name: 'notices',
      component: () => import('@/views/MyAccount/Notice/Notices.vue'),
    },
    {
      path: '/myaccount/rolling',
      name: 'rolling',
      component: () => import('@/views/MyAccount/Rolling.vue'),
    },
    {
      path: '/myaccount/coupon',
      name: 'coupon',
      component: () => import('@/views/MyAccount/Coupon.vue'),
    },
    {
      path: '/terms-and-conditions',
      name: 'termsAndConditions',
      meta: {
        withoutLogin: true,
      },
      component: () => import('@/views/TermsAndConditions.vue'),
    },
    {
      path: '/myaccount/notices/:id',
      name: 'noticeDetail',
      component: () => import('@/views/MyAccount/Notice/NoticesDetail.vue'),
    },
    {
      path: '/myaccount/referrer',
      name: 'referrer',
      component: () => import('@/views/MyAccount/Referrer.vue'),
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/views/FooterPage/PrivacyPolicy.vue'),
    },
    {
      path: '/responsible-gaming',
      name: 'responsible-gaming',
      component: () => import('@/views/FooterPage/ResponsibleGaming.vue'),
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      meta: {
        withoutLogin: true,
      },
      component: () => import('@/views/FooterPage/ContactUs.vue'),
    },
    {
      path: '/frequently-asked-questions',
      name: 'frequently-asked-questions',
      component: () => import('@/views/FooterPage/FAQ.vue'),
    },
    {
      path: '/Maintenance',
      name: 'maintenance',
      meta: {
        withoutLogin: true,
      },
      component: () => import('@/views/Maintenance.vue'),
    },
  ],
})

const isLogin = ref(getToken())
router.beforeEach((to, from, next) => {
  const token = getToken()
  isLogin.value = token !== null && token !== undefined && token !== ''
  const pageNeedLogin = to.meta === undefined || !to.meta.withoutLogin
  console.log('【路由】前置拦截器：', to.path)
  console.log('isLogin', isLogin.value)
  console.log('pageNeedLogin', pageNeedLogin)
  // console.log(to.meta === undefined)
  // console.log(!to.meta.withoutLogin)
  // console.log(!isLogin.value && needLogin)
  // console.log(isLogin.value)
  // console.log(!isLogin.value && needLogin)
  if (!isLogin.value) {
    if (useConfigStore().mustLogin) {
      useCommonStore().showLoginDialog = true
      if (to.path !== '/' && to.path !== '/register2' && to.path !== '/Maintenance') {
        next('/')
      } else {
        next()
      }
      return
    }
    if (pageNeedLogin) {
      useCommonStore().showLoginDialog = true
      next()
      return
    }
  }

  if (to.path !== '/Maintenance' && useConfigStore().ismainatence) {
    router.push('/Maintenance')
    return
  }

  next()
})

export default router
