<script setup>
import { getCurrentInstance, onMounted, ref } from 'vue'
import { useUserStore } from '@/stores/user.js'
import { transferWithdrawalAPI } from '@/api/finance.js'
import { useCommonStore } from '@/stores/common.js'

const userStore = useUserStore()
const commonStore = useCommonStore()
onMounted(() => {
  userStore.updateInfo()
})
const instance = getCurrentInstance()
const $t = instance.appContext.config.globalProperties.$t
const transferring = ref(false)
const transfer = (type) => {
  if (transferring.value) {
    return
  }
  transferring.value = true
  transferWithdrawalAPI(type).then(() => {
    userStore.updateInfo().then(() => {
      commonStore.showSuccessNotify($t('success'))
      transferring.value = false
    })
  })
}
</script>

<template>
  <div @click="$emit('close')" role="dialog" aria-modal="true" class="fade modal show" tabindex="-1" style="display: block">
    <div role="document" class="modal-dialog modal-lg modal-dialog-centered">
      <div @click.stop class="modal-content">
        <div class="modal-header">
          <p class="modal-title text-center">{{ $t('withdrawable') }} {{ $t('transfer') }}</p>
          <button @click="$emit('close')" type="button" class="close"><span aria-hidden="true">×</span></button>
        </div>
        <div class="rebate-transfer-modal modal-body">
          <div class="d-flex justify-content-between">
            <div class="wallet-box-container">
              <!--              <div class="transfer-text">{{ $t('transfer') }} {{ $t('from') }}:</div>-->
              <div class="wallet-box">
                <div class="mr-3"><img src="@/assets/media/rebate-wallet-icon.34fe4178.svg" alt="" /></div>
                <div>
                  <div class="wallet-name">{{ $t('withdrawable') }} {{ $t('wallet') }}</div>
                  <div class="wallet-amount">{{ userStore.cash }}</div>
                </div>
              </div>
            </div>
            <div class="wallet-arrow">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAWCAYAAACCAs+RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAS5SURBVHgBnVdNSJtJGJ58JlVIZRGK++PuwsKuPWzYg+sq7LIHL1kP64I/6EWpiygxF1u73UPZLaUt/bWtBbWXejAFBdtCUr1YD0ItvdSLIEql1YbUHtqASA9qEk2fN3yTTibvfJE+MMl8M/O+8z7z/sz3uWZnZ791uVy/orkEsLe3J9R/HTS+v7+ffSwpKXni9/vfcGsnJye/SyQSP6fTaQ+nR4eiVySTyRSen/X29r4SB4Brbm7OjY160P5Ac5Ey/Ocpl2OqATQG/pvonm5oaNjQlS8sLHhWV1ePYd1fJn36s+zbdry3LOtcV1fXc1EAVl1dXaq8vPwOhB7DqDQEhe2cHMVZ5vlzZSD3Xzgc/kpXXl1dnaysrByDzENxQEgSNkrxfHJ0dPRoITmLfnw+X6KoqOgWuuG0arVmvOp6OW6PVeD/0tTU1A+CIeN2u0MgO6Ua62iUZWX1Y+0X0H2mEBlLdsgzUHAXQhHay2kDziBsWpZKpf7hyLS2tiaQS2OYfwCZpBzXD0YfV/SXku6RkZGjBYlIMtvb2/fQfcR5hvOIRu5LJGk/yFQIhgzW38f6h3p4Sl1Zo7QDs/E5xk8ODg7+xE26uMGlpaVDsVisG8r9VABoTE1O2VfjWZ2H3AZO8DqMf6HrpgKwsrKSLQC6PLePdoBxhOmVYDCYUwBY6pQz9fX1t9ENC0OYZRUwpwcDKkDmf1POFBcX5+QMgfOSugc1IoZ2BGvP6GFmmYSxeB8b3kX3EU7ESMYU55Qzu7u7/ahm3+hzFGZer3eMyGBdJmcKFQB1H8iUovUNDQ39KAoRIVDOwJgQhCKUM1KZPD01RwwnWoGcOR2JRL4XBjLoGkuzk5dgztdERuaMeaUCujRRBAIg4reVqAoFd4lqOfNmZ2dnoKOjg82Z5eXlv+GdP1XdTjrls4042hVHj0jgNaMYf585lV9mA3VsT4aQDpA4hPly9fRN4apC2gIbdj0eT6Igkenp6TIoPoVurT6nXIhGYKMYwutqe3t7VJ8bHh4+DPnj6Naoh2Iov0InC5kNkLjZ3d297hYOmJmZ8dokfAYjM5s6kIniVh9oa2vLIzE+Pn4EJfoYZGtNwvbNnrOfgtcgMdjT07NKD5YTCQjSafkclBlBnoCRN5qbm/NIhEIhL5FA93fVaPWf20uZ20ROXZMkCKxHKJygJAjBWvsNN6vU1NcQhacGTCQwdwIka0Qu8Zx/EyBH4XQ1EAisq+MWRwL3Rx+REJ8AGBmD7HmOBO6UUswfh7E1+pxTqZUAiXWEaiYn9Lki9YFKIRLzFASq5Jh+QlzpVfAO7VJTU1Petwl5AncShWrtQUKVGd/EG/plhNNLbn3WI5QTW1tb/+JkqoQBXFIrFSaKQ7jQ2Nj4SjAksC4AQ6rpmTv9AiG1iUM4y3lCIuOR+fl5yol+tF+4DZzi1y6DUbTzLS0teZ7A5+5hGH4C3d+Ig5OHVZ0KXqNdxCevkQTBWlxc9OLCC6JfxSjJwCl+MfeOSCCc3gqGBKpTH5cTjkZ99DKRuObkCQl3PB6nt8g1aioJ7tsDoZH5ZpcXIb1YAk85EvT6PzExQW+/a5BZ0+dJh/r9r1+u9hvv487Ozjwvc/gAWJVCHFDQKiMAAAAASUVORK5CYII="
                alt=""
              />
            </div>
            <div class="wallet-box-container">
              <!--              <div class="transfer-text">{{ $t('transfer') }} {{ $t('to') }}:</div>-->
              <div class="wallet-box">
                <div class="mr-3"><img src="@/assets/media/main-wallet-icon.06149f48.svg" alt="" /></div>
                <div>
                  <div class="wallet-name">{{ $t('sport') }} {{ $t('bonus') }} {{ $t('wallet') }}</div>
                  <div class="wallet-amount">{{ userStore.sport_bonus }}</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!--            <p class="info-text">{{ $t('transferTip') }}</p>-->
          </div>
          <div class="text-center transfer-btn-container mt-4">
            <button @click="transfer('sport')" class="btn btn-success">{{ $t('transfer') }}</button>
          </div>
        </div>
        <div class="rebate-transfer-modal modal-body">
          <div class="d-flex justify-content-between">
            <div class="wallet-box-container">
              <!--              <div class="transfer-text">{{ $t('transfer') }} {{ $t('from') }}:</div>-->
              <div class="wallet-box">
                <div class="mr-3"><img src="@/assets/media/rebate-wallet-icon.34fe4178.svg" alt="" /></div>
                <div>
                  <div class="wallet-name">{{ $t('withdrawable') }} {{ $t('wallet') }}</div>
                  <div class="wallet-amount">{{ userStore.cash }}</div>
                </div>
              </div>
            </div>
            <div class="wallet-arrow">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAWCAYAAACCAs+RAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAS5SURBVHgBnVdNSJtJGJ58JlVIZRGK++PuwsKuPWzYg+sq7LIHL1kP64I/6EWpiygxF1u73UPZLaUt/bWtBbWXejAFBdtCUr1YD0ItvdSLIEql1YbUHtqASA9qEk2fN3yTTibvfJE+MMl8M/O+8z7z/sz3uWZnZ791uVy/orkEsLe3J9R/HTS+v7+ffSwpKXni9/vfcGsnJye/SyQSP6fTaQ+nR4eiVySTyRSen/X29r4SB4Brbm7OjY160P5Ac5Ey/Ocpl2OqATQG/pvonm5oaNjQlS8sLHhWV1ePYd1fJn36s+zbdry3LOtcV1fXc1EAVl1dXaq8vPwOhB7DqDQEhe2cHMVZ5vlzZSD3Xzgc/kpXXl1dnaysrByDzENxQEgSNkrxfHJ0dPRoITmLfnw+X6KoqOgWuuG0arVmvOp6OW6PVeD/0tTU1A+CIeN2u0MgO6Ua62iUZWX1Y+0X0H2mEBlLdsgzUHAXQhHay2kDziBsWpZKpf7hyLS2tiaQS2OYfwCZpBzXD0YfV/SXku6RkZGjBYlIMtvb2/fQfcR5hvOIRu5LJGk/yFQIhgzW38f6h3p4Sl1Zo7QDs/E5xk8ODg7+xE26uMGlpaVDsVisG8r9VABoTE1O2VfjWZ2H3AZO8DqMf6HrpgKwsrKSLQC6PLePdoBxhOmVYDCYUwBY6pQz9fX1t9ENC0OYZRUwpwcDKkDmf1POFBcX5+QMgfOSugc1IoZ2BGvP6GFmmYSxeB8b3kX3EU7ESMYU55Qzu7u7/ahm3+hzFGZer3eMyGBdJmcKFQB1H8iUovUNDQ39KAoRIVDOwJgQhCKUM1KZPD01RwwnWoGcOR2JRL4XBjLoGkuzk5dgztdERuaMeaUCujRRBAIg4reVqAoFd4lqOfNmZ2dnoKOjg82Z5eXlv+GdP1XdTjrls4042hVHj0jgNaMYf585lV9mA3VsT4aQDpA4hPly9fRN4apC2gIbdj0eT6Igkenp6TIoPoVurT6nXIhGYKMYwutqe3t7VJ8bHh4+DPnj6Naoh2Iov0InC5kNkLjZ3d297hYOmJmZ8dokfAYjM5s6kIniVh9oa2vLIzE+Pn4EJfoYZGtNwvbNnrOfgtcgMdjT07NKD5YTCQjSafkclBlBnoCRN5qbm/NIhEIhL5FA93fVaPWf20uZ20ROXZMkCKxHKJygJAjBWvsNN6vU1NcQhacGTCQwdwIka0Qu8Zx/EyBH4XQ1EAisq+MWRwL3Rx+REJ8AGBmD7HmOBO6UUswfh7E1+pxTqZUAiXWEaiYn9Lki9YFKIRLzFASq5Jh+QlzpVfAO7VJTU1Petwl5AncShWrtQUKVGd/EG/plhNNLbn3WI5QTW1tb/+JkqoQBXFIrFSaKQ7jQ2Nj4SjAksC4AQ6rpmTv9AiG1iUM4y3lCIuOR+fl5yol+tF+4DZzi1y6DUbTzLS0teZ7A5+5hGH4C3d+Ig5OHVZ0KXqNdxCevkQTBWlxc9OLCC6JfxSjJwCl+MfeOSCCc3gqGBKpTH5cTjkZ99DKRuObkCQl3PB6nt8g1aioJ7tsDoZH5ZpcXIb1YAk85EvT6PzExQW+/a5BZ0+dJh/r9r1+u9hvv487Ozjwvc/gAWJVCHFDQKiMAAAAASUVORK5CYII="
                alt=""
              />
            </div>
            <div class="wallet-box-container">
              <!--              <div class="transfer-text">{{ $t('transfer') }} {{ $t('to') }}:</div>-->
              <div class="wallet-box">
                <div class="mr-3"><img src="@/assets/media/main-wallet-icon.06149f48.svg" alt="" /></div>
                <div>
                  <div class="wallet-name">{{ $t('liveSlots') }} {{ $t('bonus') }} {{ $t('wallet') }}</div>
                  <div class="wallet-amount">{{ userStore.live_slots_bonus }}</div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!--            <p class="info-text">{{ $t('transferTip') }}</p>-->
          </div>
          <div class="text-center transfer-btn-container mt-4">
            <button @click="transfer('live&slots')" class="btn btn-success">{{ $t('transfer') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn {
  background-color: #FFDD00;
}
</style>
