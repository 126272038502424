<script setup>
import { useConfirmStore } from '@/stores/confirm.js'
import { storeToRefs } from 'pinia'

const { title, message, show } = storeToRefs(useConfirmStore())
const confirmStore = useConfirmStore()
</script>

<template>
  <div
    v-show="show"
    @click="confirmStore.cancel()"
    role="dialog"
    aria-modal="true"
    class="fade compose-message modal show"
    tabindex="-1"
    style="display: block"
  >
    <div @click.stop role="document" class="modal-dialog modal-dialog-centered" style="width: 500px">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title h4">{{ title }}</div>
          <button @click="confirmStore.cancel()" type="button" class="close"><span aria-hidden="true">×</span></button>
        </div>
        <div class="mt-3 modal-body">
          <div class="w-100">
            <p class="bold" style="font-size: 20px; margin-bottom: 30px; white-space: pre-wrap; text-align: justify; text-align-last: center">
              {{ message }}
            </p>
            <div class="text-center">
              <div v-if="confirmStore.showCancel" class="btn btn-primary" @click="confirmStore.cancel()">{{ $t('cancel') }}</div>
              <div class="btn btn-secondary" :class="{ 'ml-4': confirmStore.showCancel }" @click="confirmStore.confirm()">{{ $t('confirm') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
