import axios from 'axios'
import { clearToken, getToken } from '@/utils/token.js'
import router from '@/router'
import { i18n } from '@/main.js'
import { useUserStore } from '@/stores/user.js'
import { useCommonStore } from '@/stores/common.js'
import { useConfirmStore } from '@/stores/confirm.js'
import { useConfigStore } from '@/stores/config.js'
import * as Sentry from '@sentry/vue'

const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL,
  timeout: 50000,
  headers: { 'Content-Type': 'application/json' },
})

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)
// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    if (useConfigStore().ismainatence) {
      return response
    }

    const { showNotifyMessage } = useCommonStore()
    const status = response.data?.status
    switch (status) {
      case 0:
        // showNotifyMessage({ type: 'success', message: i18n.global.t('success') })
        break
      case 1:
        // showNotifyMessage('error', i18n.global.t('errorMessage1'))
        break
      case 2:
        showNotifyMessage('error', i18n.global.t('errorMessage2'))
        break
      case 3:
        const confirmStore = useConfirmStore()
        confirmStore.showCancel = false
        confirmStore.showConfirmDialog(
          i18n.global.t('sessionExpiredTitle'),
          i18n.global.t('sessionExpiredTip'),
          () => {
            confirmStore.show = false
            confirmStore.showCancel = true
            // showNotifyMessage('error', i18n.global.t('errorMessage3'))
            const userStore = useUserStore()
            userStore.logout()
            router.replace('/')
          },
          () => {
            confirmStore.show = false
            confirmStore.showCancel = true
            const userStore = useUserStore()
            userStore.logout()
            router.replace('/')
          },
        )
        break
      case 4:
        showNotifyMessage('error', i18n.global.t('errorMessage4'))
        break
      case 5:
        // showNotifyMessage('error', i18n.global.t('errorMessage5'))
        break
      case 6:
        showNotifyMessage('error', i18n.global.t('errorMessage6'))
        break
      case 7:
        showNotifyMessage('error', i18n.global.t('errorMessage7'))
        break
      case 8:
        showNotifyMessage('error', i18n.global.t('errorMessage8'))
        break
      case 9:
        showNotifyMessage('error', i18n.global.t('errorMessage9'))
        break
      case 10:
        showNotifyMessage('error', i18n.global.t('errorMessage10'))
        break
      case 11:
        showNotifyMessage('error', i18n.global.t('errorMessage11'))
        break
      case 12:
        showNotifyMessage('error', i18n.global.t('errorMessage12'))
        break
      case 13:
        showNotifyMessage('error', i18n.global.t('errorMessage13'))
        break
      case 14:
        showNotifyMessage('error', i18n.global.t('errorMessage14'))
        break
      case 15:
        showNotifyMessage('error', i18n.global.t('errorMessage15'))
        break
      case 16:
        showNotifyMessage('error', i18n.global.t('errorMessage16'))
        break
      case 17:
        showNotifyMessage('error', i18n.global.t('errorMessage17'))
        break
      case 18:
        showNotifyMessage('error', i18n.global.t('errorMessage18'))
        break
      case 19:
        showNotifyMessage('error', i18n.global.t('errorMessage19'))
        break
      case 21:
        showNotifyMessage('error', i18n.global.t('errorMessage21'))
        break
      case 20:
        showNotifyMessage('error', i18n.global.t('registerTip'))
        break
      case 22:
        // showNotifyMessage('error', i18n.global.t('duplicateDepositOrderInfo', { amount: response.data.data.amount }))
        break
      case 24:
        showNotifyMessage('error', i18n.global.t('financeMaintenance'))
        break
      case 311:
        showNotifyMessage('error', i18n.global.t('errorMessage311'))
        break
      case 312:
        showNotifyMessage('error', i18n.global.t('errorMessage312'))
        break
      default:
        showNotifyMessage('error', response.data?.message || 'request failed')
    }
    if (response.data.status !== 0) {
      return Promise.reject(response)
    }
    return response
  },
  (error) => {
    console.warn(error)

    try {
      const { showNotifyMessage } = useCommonStore()

      const status = error.response?.status
      switch (status) {
        case 401:
          showNotifyMessage('error', i18n.global.t('errorMessage3'))
          clearToken()
          router.replace('/')
          break
        case 403:
          clearToken()
          router.replace('/')
          showNotifyMessage('error', i18n.global.t('errorMessage3'))
          break
        case 404:
          showNotifyMessage('error', i18n.global.t('errorMessage4'))
          break
        case 500:
          showNotifyMessage('error', i18n.global.t('errorMessage1'))
          break
        default:
          showNotifyMessage('error', error.response?.data?.message || 'request failed')
      }
    } catch (error) {
      console.warn(error) // 记录捕获的错误
    }

    return Promise.reject(error)
  },
)

export default instance
