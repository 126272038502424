<script setup>
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user.js'
import { computed, ref } from 'vue'
import TransferDialog from '@/components/TransferDialog/TransferDialog.vue'
import { formatAmount } from '@/utils/utils.js'

defineProps({
  show: Boolean,
})

const emits = defineEmits(['close'])
const router = useRouter()

const goView = (path) => {
  router.push(path)
  emits('close')
}
const userStore = useUserStore()
const account = computed(() => userStore.account)
const totalBalance = computed(
  () => (
    parseFloat(userStore.balance) +
    parseFloat(userStore.cash) +
    parseFloat(userStore.sport_bonus) +
    parseFloat(userStore.live_slots_bonus) +
    parseFloat(userStore.coupon)
  ).toFixed(2)
)
const balance = computed(() => userStore.balance)
const cash = computed(() => userStore.cash)
const sport_bonus = computed(() => userStore.sport_bonus)
const live_slots_bonus = computed(() => userStore.live_slots_bonus)
const coupon = computed(() => userStore.coupon)
const showTransferDialog = ref(false)
const showTransfer = () => {
  showTransferDialog.value = true
  emits('close')
}
const showBalanceDetail = ref(false)
</script>

<template>
  <transfer-dialog v-if="showTransferDialog" @close="showTransferDialog = false" />
  <div v-show="show" @click="$emit('close')"
    style="width: 100vw; height: 100vh; position: fixed; left: 0; top: 0; z-index: 190"></div>
  <div @click.stop tabindex="-1" role="menu" aria-hidden="true" style="z-index: 200"
    class="dropdown-menu dropdown-menu-right user-menu-position" :class="{ show: show }">
    <div class="vip-badge-username mb-2">
      <div>
        <img :src="userStore.vip_icon" />
        <span class="account-name--green">{{ account }}</span>
      </div>
    </div>
    <div class="balance">
      <div class="d-flex justify-content-between align-items-center">
        <div class="col-auto">
          <p>{{ $t('totalBalance') }}:</p>
          <b>
            <p class="highlighted-text">KRW: {{ formatAmount(totalBalance) }}</p>
          </b>
        </div>
        <div class="col-auto">
          <button class="btn btn-success" id="rebate-transfer-btn" @click="goView('/myaccount/rolling')">
            <img src="@/assets/media/transfer-icon.9df141ca.svg" alt="" class="rebate-transfer-arrow" />&nbsp;&nbsp;{{
              $t('rolling') }}
          </button>
          <button @click="userStore.updateInfo()" id="refresh-wallet-btn" style="padding: 10px">
            <img class="ImgFluid" src="@/assets/img/icons/inactive/refresh_icon.svg" style="cursor: pointer" />
          </button>
        </div>
      </div>
      <div style="transition: all 0.3s ease-in-out; overflow: hidden"
        :style="{ height: showBalanceDetail ? '230px' : '0px' }">
        <div class="d-flex justify-content-between align-items-center">
          <div class="col">
            <p>{{ $t('withdrawable') }}</p>
            <b>
              <p class="highlighted-text">KRW: {{ formatAmount(cash > 0 ? cash : 0) }}</p>
            </b>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="col">
            <p>{{ $t('normalBalance') }}</p>
            <b>
              <p class="highlighted-text">KRW: {{ formatAmount(balance) }}</p>
            </b>
          </div>
          <div class="col">
            <p>{{ $t('sportBonus') }}</p>
            <b>
              <p class="highlighted-text">KRW: {{ formatAmount(sport_bonus) }}</p>
            </b>
          </div>
          <!--          <div class="col">-->
          <!--            <p>{{ $t('coupon2') }}</p>-->
          <!--            <b>-->
          <!--              <p class="highlighted-text">{{ formatAmount(coupon) }}</p>-->
          <!--            </b>-->
          <!--          </div>-->
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="col">
            <p>{{ $t('coupon2') }}</p>
            <b>
              <p class="highlighted-text">KRW: {{ formatAmount(coupon) }}</p>
            </b>
          </div>

          <div class="col">
            <p>{{ $t('liveBonus') }}</p>
            <b>
              <p class="highlighted-text">KRW: {{ formatAmount(live_slots_bonus) }}</p>
            </b>
          </div>
        </div>
      </div>
      <div @click="showBalanceDetail = !showBalanceDetail"
        style="border-top: 1px solid #a5a5a7; padding-top: 10px; cursor: pointer">
        <div class="balance-arrow" :class="{ down: showBalanceDetail }"></div>
      </div>
    </div>
    <div>
      <!--      <button tabindex="0" role="menuitem" class="dropdown-item">-->
      <!--        <a @click="goView('/myaccount/referrer')">-->
      <!--          <div class="d-flex justify-content-between align-items-center">-->
      <!--            <div class="col-auto">-->
      <!--              <p class="referrer-dropdown d-flex align-items-center">{{ $t('referrer') }}</p>-->
      <!--            </div>-->
      <!--            <div class="col-auto arrow-after"></div>-->
      <!--          </div>-->
      <!--        </a>-->
      <!--      </button>-->
      <button @click="goView('/myaccount/deposit')" tabindex="0" role="menuitem" class="dropdown-item">
        <a>
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-auto">
              <p class="deposit-dropdown d-flex align-items-center">{{ $t('deposit') }}</p>
            </div>
            <div class="col-auto arrow-after"></div>
          </div>
        </a>
      </button>
      <button @click="goView('/myaccount/withdrawal')" tabindex="0" role="menuitem" class="dropdown-item">
        <a>
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-auto">
              <p class="withdrawal-dropdown d-flex align-items-center">{{ $t('withdrawal') }}</p>
            </div>
            <div class="col-auto arrow-after"></div>
          </div>
        </a>
      </button>
      <button @click="goView('/myaccount/coupon')" tabindex="0" role="menuitem" class="dropdown-item">
        <a>
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-auto">
              <p class="bonus-dropdown d-flex align-items-center">{{ $t('coupon') }}</p>
            </div>
            <div class="col-auto arrow-after"></div>
          </div>
        </a>
      </button>
      <button @click="goView('/myaccount/rolling')" tabindex="0" role="menuitem" class="dropdown-item">
        <a>
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-auto">
              <p class="rolling-dropdown d-flex align-items-center">{{ $t('rolling') }}</p>
            </div>
            <div class="col-auto arrow-after"></div>
          </div>
        </a>
      </button>
      <button @click="goView('/myaccount/records?activeTab=monetaryRewards')" tabindex="0" role="menuitem"
        class="dropdown-item">
        <a>
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-auto">
              <p class="rebate-dropdown d-flex align-items-center">{{ $t('monetaryRewards') }}</p>
            </div>
            <div class="col-auto arrow-after"></div>
          </div>
        </a>
      </button>
      <!--      <button @click="goView('/myaccount/records?activeTab=gameHistory')" tabindex="0" role="menuitem" class="dropdown-item">-->
      <!--        <a>-->
      <!--          <div class="d-flex justify-content-between align-items-center">-->
      <!--            <div class="col-auto">-->
      <!--              <p class="history-dropdown d-flex align-items-center">{{ $t('gameHistory') }}</p>-->
      <!--            </div>-->
      <!--            <div class="col-auto arrow-after"></div>-->
      <!--          </div>-->
      <!--        </a>-->
      <!--      </button>-->
      <button @click="goView('/myaccount/profile')" tabindex="0" role="menuitem" class="dropdown-item">
        <a>
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-auto">
              <p class="profile-dropdown d-flex align-items-center">{{ $t('profile') }}</p>
            </div>
            <div class="col-auto arrow-after"></div>
          </div>
        </a>
      </button>
      <button @click="goView('/sports-betting-game-rules')" tabindex="0" role="menuitem" class="dropdown-item">
        <a>
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-auto">
              <p class="notice-dropdown d-flex align-items-center">{{ $t('bettingRules') }}</p>
            </div>
            <div class="col-auto arrow-after"></div>
          </div>
        </a>
      </button>
      <button @click="userStore.logout()" tabindex="0" role="menuitem" class="logout-dropdown-item dropdown-item">
        <a>
          <div class="d-flex justify-content-between align-items-center">
            <div class="col-auto">
              <p class="logout-dropdown d-flex align-items-center">{{ $t('logout') }}</p>
            </div>
            <div class="col-auto arrow-after"></div>
          </div>
        </a>
      </button>
    </div>
  </div>
</template>

<style scoped>
.balance-arrow {
  content: url('@/assets/img/icons/inactive/arrow.svg');
  width: 10px;
  height: auto;
  transform: rotate(90deg);
  transition: transform 0.3s;
  margin: 0 auto;
}

.balance-arrow.down {
  transform: rotate(270deg);
}
</style>

<style>
.dropdown-item.active,
.dropdown-item:active {
  background: inherit;
  outline: none;
}

.dropdown-item.hover,
.dropdown-item:hover {
  background: inherit;
  outline: none;
}

#header .login-area .account-name .user-menu-position {
  position: absolute;
  will-change: transform;
  top: 0;
  left: 0;
  transform: translate3d(-266px, 33px, 0px);
}
</style>
