<script setup>
import { useRouter } from 'vue-router'
import { getCurrentInstance, ref } from 'vue'
import { useUserStore } from '@/stores/user.js'
import { generateFingerprint } from '@/utils/utils.js'
import { useConfigStore } from '@/stores/config.js'

const configStore = useConfigStore()
const emits = defineEmits(['close'])
const router = useRouter()
const goView = (path) => {
  router.push(path)
  emits('close')
}
const showInvalid = ref(false)
const username = ref('')
const password = ref('')
const instance = getCurrentInstance()

const $t = instance.appContext.config.globalProperties.$t
const userStore = useUserStore()
const invalidTip = ref('')
const login = async () => {
  userStore
    .login({
      account: username.value,
      password: password.value,
      platform: 'PC',
      fingerprint: await generateFingerprint(),
      domain: window.location.hostname,
    })
    .then(() => {
      // goView('/')
    })
    .catch((err) => {
      console.log(err)
      invalidTip.value = $t(`errorMessage${err.data.status}`)
      showInvalid.value = true
    })
}
const showPassword = ref(false)

function onRegister() {
  if (configStore.mustLogin) {
    router.push('/register2')
  } else {
    router.push('/register')
    emits('close')
  }
}

function onClose() {
  if (configStore.mustLogin) {
    return
  }
  emits('close')
}
</script>

<template>
  <div @click="onClose" role="dialog" aria-modal="true" class="fade modal show" tabindex="-1" style="display: block">
    <div role="document" class="modal-dialog modal-dialog-centered">
      <div @click.stop class="modal-content">
        <div class="modal-header">
          <p style="color: whitesmoke" class="modal-title text-center" id="LoginModal">{{ $t('login') }} {{ $t('register') }}</p>
          <button v-if="!configStore.mustLogin" @click="onClose" class="close"><span aria-hidden="true">×</span></button>
        </div>
        <div class="mb-5 modal-body" >
          <div class="col text-center" style="margin-bottom: 25px">
            <img src="@/assets/img/logo/IKEALogo.png" alt="IKEA" />
          </div>
          <form @submit.prevent="login">
            <div class="form-group">
              <label for="partnerID" class="sr-only">ID</label>
              <div class="input-group">
                <input
                  v-model="username"
                  type="text"
                  class="form-control form-control-md dark inputPlaceholder"
                  :class="{ 'is-invalid': showInvalid }"
                  placeholder="ID"
                  name="login_name"
                  required
                />
                <div class="invalid-tooltip">{{ invalidTip }}</div>
              </div>
            </div>
            <div class="form-group">
              <label for="partnerPassword" class="sr-only">Password</label>
              <div class="input-group login_show_hide_password">
                <input
                  v-model="password"
                  :type="showPassword ? '' : 'password'"
                  class="form-control form-control-md dark"
                  placeholder="Password"
                  name="password"
                  required
                />
                <div class="input-group-addon">
                  <a @click="showPassword = !showPassword"><i class="fa" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i></a>
                </div>
              </div>
            </div>
            <div class="btn-container">
              <button class="btn btn-secondary" style="width: 100%">{{ $t('login') }}</button>
              <p class="or">OR</p>
              <a @click="onRegister" class="btn btn-success">{{ $t('register') }}</a>
            </div>
            <div class="notetxt mt-4">
              <p>
                {{ $t('loginTip') }}
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-body {
  margin-bottom: 15px !important;
}
</style>
