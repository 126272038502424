import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useConfigStore = defineStore(
  'config',
  () => {
    const language = ref('ko')
    const splashPreventDate = ref('')
    const hasMessage = ref(false)
    const ismainatence = ref(false)
    // 必须先登录
    const mustLogin = ref(true)

    return {
      language,
      splashPreventDate,
      hasMessage,
      ismainatence,
      mustLogin,
    }
  },
  {
    persist: true,
  },
)
