import service from '@/utils/request.js'

export const depositInitAPI = (data) => service.post('/finance/deposit/init', data)
export const depositAPI = (data) => service.post('/finance/deposit', data)
export const withdrawInitAPI = (data) => service.post('/finance/withdraw/init', data)
export const withdrawAPI = (data) => service.post('/finance/withdraw', data)
export const depositHistoryAPI = (data) => service.post('/finance/deposit/history', data)
export const withdrawHistoryAPI = (data) => service.post('/finance/withdraw/history', data)
export const transferWithdrawalAPI = (type) => service.post(`/finance/transferWithdrawal/${type}`)
export const transferRebateAPI = () => service.post(`/finance/transferRebate`)
export const cancelRollingAPI = (data) => service.post('/finance/rolling/cancel', data)
