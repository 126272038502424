export default {
  financeMaintenance: '입출금 점검 중입니다.',
  PaybackPercent: '페이백 퍼센트',
  btiSports: '비티아이',
  bt1: '비티원',
  betConstruct: '벳컨스트럭',
  liveCasino: '라이브 카지노',
  slot: '슬롯',
  liveHoldEm: '라이브 홀덤',
  promos: '이벤트',
  promotions: '이벤트',
  vip: '등급별 혜택',
  rules: '공지사항',
  topWinners7: '지난 7일 최고 출금왕',
  topWinners24: '지난 24시간 최고 출금왕',
  playerId: '회원ID',
  multi: '멀티',
  odds: '배당률',
  betAmount: '배팅금액',
  winAmount: '출금금액',
  title: '제목',
  date: '날짜',
  action: '액션',
  deposit: '입금',
  withdrawal: '출금',
  coupon: '쿠폰리스트',
  coupon2: '쿠폰지갑',
  records: '기록',
  rolling: '롤링현황',
  profile: '프로필',
  notices: '쪽지',
  dontShowToday: '오늘 다시 표시하지 않기',
  closeAll: '모두닫기',
  accountDetails: '계정 세부정보',
  bankDetails: '은행 세부 정보',
  changePassword: '비밀번호 변경',
  account: '계정',
  name: '이름',
  dateRegistered: '가입날짜',
  contactNumber: '전화번호',
  update: '업데이트',
  bankName: '은행이름',
  accountName: '예금주',
  accountNumber: '계좌 번호',
  verifyOldPassword: '기존 비밀번호 확인',
  enterNewPassword: '새 비밀번호 입력',
  reEnterNewPassword: '새 비밀번호 재입력',
  fieldRequired: '이 내용은 필수입니다.',
  myAccountDetails: '내 계정 세부 정보',
  passwordsDoNotMatch: '비밀번호가 일치하지 않습니다.',
  changePasswordSuccess: '비밀번호 변경 성공',
  save: '저장',
  logout: '로그아웃',
  amountRangeTip: '금액은 최소 {min} 이상, 최대 {max} 이하여야 합니다.',
  balanceNotEnough: '잔액이 부족합니다',
  depositAmountError: '입금 금액 오류',
  depositAlreadyExist: '이미 존재하는 입금건입니다',
  promotionNotExist: '프로모션이 존재하지 않습니다',
  depositOrderNotExist: '입금 주문이 존재하지 않습니다',
  depositOrderNotPending: '대기 중인 입금 주문이 아닙니다',
  withdrawExist: '출금이 존재합니다',
  withdrawAmountError: '출금 금액 오류',
  withdrawOrderNotExist: '출금 주문이 존재하지 않습니다',
  withdrawOrderNotPending: '대기 중인 출금 주문이 아닙니다',
  today: '오늘',
  yesterday: '어제',
  last3days: '최근 3일',
  last7days: '최근 7일',
  last30days: '최근 30일',
  amountReceived: '받은금액',
  reset: '재설정',
  search: '검색',
  transactionID: '거래ID',
  bonus: '보너스',
  amount: '금액',
  status: '상태',
  noResultsFound: '결과가 없습니다',
  loadingData: '데이터 로딩중',
  passwordTip: '비밀번호는 대문자, 소문자, 숫자를 포함하여 최소 6자 이상이어야 합니다.',
  verifyPasswordTip: '확인 비밀번호는 비밀번호와 일치해야 합니다.',
  sendCodeTip: '{seconds}초 후에 다시 전송',
  phoneTip: '유효한 전화번호를 입력하세요.',
  agentOrUserNotExistTip: '에이전트 코드 또는 추천인이 존재하지 않습니다',
  createAnAccount: '계정생성',
  personalInfo: '개인정보',
  bankAccountName: '예금주',
  bank: '은행',
  contactEmailTip: '중복 분구 수정: 중복된 경우 고객센터로 문의주세요 텔레그램',
  mobileNo: '휴대폰 번호',
  mobileNumberFormatTip: '휴대폰 번호를 다음 형식으로 입력하세요',
  verifyCode: '인증코드',
  referredBy: '추천인 (사용자 ID)',
  agentCode: '에이전트 코드',
  register: '회원가입',
  termsAndConditions: '이용약관',
  totalBalance: '전체지갑',
  withdrawable: '출금가능 잔액',
  rebate: '캐시백',
  sportBonus: '스포츠 보너스',
  liveBonus: '카지노 보너스',
  transfer: '이체',
  transferTip: '출금 가능한 잔액이 최소 금액을 충족해야 합니다.',
  from: '부터',
  to: '까지',
  wallet: '지갑',
  all: '전체',
  sport: '스포츠',
  liveSlots: '라이브 & 슬롯',
  promotion: '프로모션',
  type: '유형',
  percentage: '요율',
  rollingType: '보너스 유형',
  bonusType: '보너스 유형',
  promotionDetail: '프로모션 세부 사항',
  currentProgress: '롤링 진행상황',
  remaining: '휴효기간',
  operation: '액션',
  days: '일',
  infinite: '무기한',
  inProgress: '진행중',
  cancel: '취소',
  directReferral: '추천인',
  indirectReferral: '간접 추천인',
  gameHistory: '게임기록',
  result: '결과',
  withdrawForm: {
    amount: '금액',
    amountReceived: '출금금액',
    bankName: '은행이름',
    accountName: '예금주',
    accountNumber: '계좌번호',
  },
  history: '기록',
  mine: '본인',
  view: '보기',
  rebateAmount: '캐시백 잔액',
  min: '최소',
  max: '최대',
  submit: '확인',
  noBonus: '보너스 선택',
  login: '로그인',
  members: '회원',
  rebateTip: '보너스 없이 신청',

  loginTip: '로그인에 문제가 있으시면 고객센터로 문의해주세요',
  sendCode: '코드전송',
  details: '세부정보',
  joinNow: '지금 가입하기',
  bonusEventConditions: '보너스 이벤트 조건',
  sports: '스포츠',
  slots: '슬롯',
  wagering: '베팅',
  minimumDeposit: '최소 입금액',
  minimumOdds: '최소 배당률',
  minimumParlay: '최소폴드',
  effectiveDays: '유효한 날짜',
  maximumPayoutAmount: '최대 적중금',
  noPromotionTip: '현재 새로운 프로모션이 없습니다.',
  depositAmount: '입금액',
  rewardAmount: '보상금액',
  gameType: '게임유형',
  pending: '진행중',
  success: '성공',
  failed: '실패',
  canceled: '취소됨',
  timeout: '시간초과',
  bettingRules: '공지사항',
  topdeposit: '입출금',
  footerpromotion: '이벤트',
  quickLinks: '빠른링크',
  products: '제품',
  help: '도움말',
  faqs: '자주묻는 질문',
  contactUs: '문의하기',
  supportfooter: '지원',
  license: '라이선스',
  providers: '공급업체',
  event: '이벤트',
  rollingCondition: '롤링조건',
  select: '선택',
  refuse: '거절',
  expired: '만료됨',
  unused: '미사용',
  used: '사용됨',
  recycled: '재활용됨',
  referrer: '추천인',
  couponID: '쿠폰ID',
  validityPeriod: '유효기간',
  useTime: '사용시간',
  referrerLink: '추천인 링크',
  copy: '복사',
  copied: '복사완료',
  currentMonthInvitationCapacity: '이번달 초대 가능인원',
  totalInvited: '총 초대인원',
  referrerHierarchy: '추천인 등급',
  rewardCycle: '금액',
  totalBet: '총베팅금',
  rebatePercent: '리베에트 커미션',

  errorMessage1: '서버 내부 오류',
  errorMessage2: '유효하지 않는 요청입니다',
  errorMessage3: '인증이 필요합니다',
  errorMessage4: '데이터가 존재하지 않음',
  errorMessage5: '사용자 이름과 비밀번호가 일치하지 않음',
  errorMessage6: '기존 비밀번호가 잘못됨',
  errorMessage7: '사용자가 존재하지 않음',
  errorMessage8: '출금 금액이 최소 금액보다 커야 합니다',
  errorMessage9: '잔액이 부족합니다',
  errorMessage10: '이미 존재하는 사용자',
  errorMessage11: '5분 후에 인증코드를 다시 요청 할수있습니다.',
  errorMessage12: '인증 코드가 틀렸습니다',
  errorMessage13: '비밀번호 입력 제한 횟수 초과',
  errorMessage14: '사용자가 차단 되었습니다',
  errorMessage15: '금액이 유효하지 않음',
  errorMessage16: '쿠폰이 이미 존재합니다',
  errorMessage20: '고객님의 계정은 현재 검토 중입니다. 검토가 완료될 때까지 기다려 주시기 바랍니다.',
  errorMessage17: '은행 카드가 이미 존재합니다',
  errorMessage18: '계정이 이미 존재합니다',
  errorMessage19: '전화번호가 이미 존재합니다',
  errorMessage21: '회원가입 심사가 거부되었습니다. 고객센터로 연락주세요.',
  errorMessage311: '계좌 준비중입니다 고객센터에 연락주세요',
  errorMessage312: '이벤트를 사용할수 없습니다',
  expireTime: '만료시간',
  useWithdrawable: '출금 가능잔액 사용',
  withdrawableBalance: '출금 가능잔액',
  withdrawableRangeTip: '출금 가능 잔액은 최소 10,000 이상이어야 하고, 최대 출금 가능한 잔액 이내여야 합니다.',
  monetaryRewards: '캐시백',
  rebateTap: '리베이트',
  cashback: '캐시백',
  transfering: '이체중',
  transferFailed: '이체실패',
  sendCodeVerify: '코드확인',
  confirm: '확인',
  sending: '보내는중',
  remainingValidDays: '사용기한',
  use: '사용하기',
  deadline: '마감',
  depositAmountAtLeast: '최소 {amount}원 이상이여야 합니다 .',
  depositAmountAtMost: '최대 {amount}만원을 초과할수 없습니다 .',
  withdrawMin: '최소 30,000원 이상이여야 합니다 ',
  withdrawMax: '최대 9,000,000원 이하여야 합니다',

  balance: '잔액',
  liveSlotsBonus: '라이브 및 슬롯 보너스',
  cash: '출금가능 잔액',
  confirmMessage: '진행하시겠습니까?',
  walletType: '지갑종류',
  confirmCancelRollingMessage:
    '쿠폰롤링 취소\n' +
    '   쿠폰 잔액이 자동으로 소멸됩니다.\n' +
    '\n' +
    '보너스 롤링 취소\n' +
    '입금액 및 보너스를 사용하여 발생한 모든 수익및 보너스 금액이 회수되며 남은 잔액은 일반잔액으로 전환되며 1배의 롤링이 됩니다.\n',
  live: '라이브',
  minTransferAmount: '최소 이체금액',
  day: '일',
  week: '주',
  month: '월',

  normalBalance: '일반지갑',
  registerTip: '고객님의 계정은 현재 검토 중입니다. 검토가 완료될 때까지 기다려 주시기 바랍니다.',
  gameTypeBonusTip:
    '입장하신 게임 유형이 이벤트 충전 유형과 다릅니다. 스포츠에 대한 {bonus} 금액은 사용할 수 없으며, {balance} 금액만 사용할 수 있습니다.',
  duplicateDepositOrderInfo: '예금주명과 입금액의 중복으로 인해 입금액이 {amount}으로 변경되었습니다. 입금액을 다시 신청해주세요.',
  depositTimeLimit: '입출금 시간 제한',
  depositTimeLimitInfo: '은행점검으로 입금불가 시간은 23:30에서 00:30 입니다.',
  withdrawTimeLimit: '충전 시간 제한',
  withdrawTimeLimitInfo: '은행점검으로 출금불가 시간은 23:30에서 00:30 입니다.',
  sessionExpiredTitle: '세션 만료됨',
  sessionExpiredTip: '중복 로그인이 되었습니다. 확인 후 다시 로그인 해주세요.',
  back: '뒤로',
  loginUserNamePlaceholder: '영어와 숫자를 혼합하여 최소6글자 이상 입력',
  PopularGames: '인기게임',
  Shortcuts: '바로가기',

  financemaintian: '입출금 점검중입니다.잠시뒤 시도해주세요',
  depositToBankTip: '입금계좌는 쪽지함에서도 확인이 가능합니다',

  depositNoticeTitle: '입금 시 주의 사항!',
  depositMinimum:
    '입금은 최소 10,000원부터 가능하며, 1,000원 단위 또는 100원 단위로는 입금 처리가 불가능합니다. 1회 최대 입금 금액은 5,000,000원입니다. 최대 금액 이상 입금 시에는 1회 5,000,000원 이하로 분할 입금 및 신청 바랍니다.',
  depositOverTime: '(오 입금 시 입금 처리까지 인증 절차로 인해 시간이 길어질 수 있습니다.)',
  openBankingWarning: 'Toss, Fink, KakaoPay 등 오픈뱅킹 어플 서비스는 지원하지 않아 정상 입금 처리가 불가능합니다.',
  checkDeposit: '수표 입금은 절대 불가합니다.',
  bankInspectionNotice:
    '매일 23:50 ~ 00:10, 휴일 다음 첫 영업일 새벽에는 은행 점검으로 인해 계좌 이체가 지연될 수 있습니다. 또한 몇몇 은행은 추가 점검 시간이 따로 있으니 이점 유념하시기 바랍니다.',
  depositApplicationNotice:
    '입금 신청 버튼을 누르시면 입금 신청과 동시에 입금하실 계좌가 발급됩니다. (가입하시고 처음 이용하시는 회원님께서는 별도의 본인 인증 절차와 계좌 발급을 위한 등록을 위해 고객센터로 문의 바랍니다.)',
  ownAccountOnly: '입금 시 반드시 가입하신 본인 계좌에서만 입금 가능하며 타 은행 및 3자 입금은 불가능합니다.',
  contactCustomerService: '입금 계좌 확인이 어려우신 경우 텔레그램 {telegram} 또는 좌측 하단 실시간 채팅을 통해 고객센터로 연락 바랍니다.',

  withdrawalNoticeTitle: '출금 시 주의 사항!',
  rollingNotice: '충전 금액 관련 필요한 롤링 달성 시 출금이 가능합니다. (롤링 관련 내용은 MY 페이지 > 롤링 현황 참고 바랍니다.)',
  exchangeLimitInfo:
    '★ 1회 최대 환전, 1일 최대 환전 금액 안내\n1회 최소 환전 금액 : 30,000 원\n1회 최대 환전 금액 : 9,000,000 원\n1일 최대 환전 금액 : 200,000,000 원',
  approvalDelayNotice: '환전 대기 인원이 많은 경우 및 은행 관련 점검 시간에는 출금 승인이 지연될 수 있습니다.',
  multipleExchangeNotice: '1일 2회 이상 환전은 마지막 환전 후 2시간 이후 다시 신청해 주시기 바랍니다.',
  AccountOnly: '가입 시 기재해주신 본인 명의 계좌로만 출금이 가능하니 참고 바랍니다.',
  inspectionNotice:
    '매일 23:50 ~ 01:00 출금 관련 점검으로 인해 출금이 불가능합니다. 해당 시간에 출금하실 회원분들께서는 점검 종료 후에 출금 신청 바랍니다.',

  handicapBettingGuideTitle: '1.핸디캡 언오버 배팅관련 안내',
  handicapBettingGuideContent: `
안녕하세요. IKEA 입니다.<br>
핸디캡 및 언오버 0.75 / 0.25 의 경우<br>
배팅판의 표기 방식은 0.75 또는 0.5, 1 / 0.25 또는 0, 0.5 로 표기되어있습니다.<br><br>

◆ 핸디캡 0.75 / 0.5, 1 배팅의 경우<br>
0.75점 핸디캡에 10만원을 배팅하였을 경우<br>
0.5점 핸디캡에 5만원 1점 핸디캡에 5만원을 배팅한 것으로 생각하시면 됩니다.<br><br>
예) A 팀과 B팀의 2.0 배당 경기에서 A팀에 0.75 + 핸디캡을 배팅하였을 경우<br>
A : B 팀의 경기 결과가 0 : 1 으로 종료되었을 시<br>
0.5점은 낙첨 1점은 적특이기 때문에 5만원의 손실이 됩니다.<br>
최소 0 : 2로 경기가 종료되어야 10만원 모두 낙첨 처리가 됩니다.<br><br>

A : B 팀의 경기 결과가 0 : 0 으로 종료되었을 시<br>
0.5점 과 1점 배팅이 모두 당첨되었기 때문에 10만원의 당첨금이 지급됩니다.<br>
동점 이상의 점수를 A팀에서 획득시에는 당첨 결과처리가 됩니다.<br><br>

◆ 핸디캡 0.25 // 0, 0.5 배팅의 경우<br>
0.25점 핸디캡에 10만원을 배팅하였을 경우<br>
0.5점 핸디캡에 5만원 0 점 핸디캡에 5만원을 배팅한 것으로 생각하시면 됩니다.<br><br>
예) A 팀과 B팀의 2.0 배당 경기에서 A팀에 0.25 + 핸디캡을 배팅하셨을 경우<br>
A : B 팀의 경기 결과가 0 : 0 으로 종료되었을 시<br>
0.5점은 당첨 0점은 적특이기 때문에 절반의 금액인 5만원의 당첨금이 지급됩니다.<br>
최소 1 : 0 으로 경기가 종료되어야만 10만원 모두 당첨 처리됩니다,<br><br>

A : B 팀의 경기 결과가 0 : 1 으로 종료되었을 시<br>
0.5점 0점 모두 낙첨되었으므로 10만원 모두 낙첨처리됩니다.<br><br>

◆ 언오바 0.75 // 0.5, 1 배팅의 경우<br>
◆ 언오바 0.25 // 0, 0.5 배팅의 경우<br>
언오바 경우에도 핸디캡과 동일하게<br>
0.75 언오버 배팅은 0.5점 과 1점을 반반씩 배팅하는 것이며<br>
0.25 언오버 배팅은 0점과 0.5점을 반반씩 배팅하는 것으로 생각하시면 됩니다.<br>
경기 결과처리의 경우에도 핸디캡과 동일하게 생각하시면 됩니다.<br><br>

상기 내용을 필히 숙지하여 주시기 바라며 이에 따른 불이익을 받지 않도록 이용부탁드립니다.<br>
항상 최선을 다하는 IKEA 이 되도록 노력하겠습니다.<br><br>
감사합니다.
`,
  IKEADepositWithdrawTitle: '2. IKEA 입/출금 관련 내용',
  IKEADepositWithdrawContent: `안녕하세요 IKEA 입니다.<br><br>
충전과 환전 관련 내용을 말씀드리겠습니다.<br><br>
당사의 규정상 입금후 출금 까지 필요한 롤링을 하지 않고<br>
출금신청을 할 경우 악이용이라 판단하여 환전에 재제를 할수도 있으니<br>
상기 내용을 필히 숙지하여 주시기 바라며,<br>
이에 따른 불이익을 받지 않도록 이용부탁드립니다.<br><br>
★ 1회 최대 환전, 1일 최대 환전 금액 안내<br><br>
♦ 1회 최대 환전금액 : 2,000만원, 1일 최대 환전금액 : 1억 원<br><br>
♦ 1일 2회 이상 환전은 마지막 환전 후 2시간 이후 다시 신청해 주시기 바랍니다<br><br>
항상 최선을 다하는 IKEA 이 되도록 노력하겠습니다.<br><br>
감사합니다.`,

  liveHandicapBettingGuideTitle: '3. Live 핸디 언오버 배팅관련 안내',
  liveHandicapBettingGuideContent: `안녕하세요. IKEA 입니다.<br><br>
라이브 배팅중 핸디캡과 언오버의 배팅에 관한 문의가 많아 공지합니다.<br><br>
라이브 배팅시 핸디캡의 경우 배팅하시는 시점에 0:0상황이 됩니다.<br>
배당판의 해당 경기에 표기되는 현재스코어가 회원님께서 배팅하시는 시점이 되고,<br>
이 시점 이후로 나오는 스코어가 적용됩니다.<br><br>
간혹 올라오는 라이브 핸디캡 배팅이 이 전 스코어를 포함하는 경우가 있습니다.<br>
이런 부분에 대해서 이건 아닌데 배당이 이상하네 이런 생각을 가지게 되시면<br>
미리 고객문의 또는 센터로 연락을 하시고 물어보시고 배팅하시기 바랍니다.<br><br>
배팅사의 규정 미숙지 후 배팅을 하시고, 발생하는 문제는 도움을 드릴 수 없습니다.<br>
에이전시에서는 결과처리 등의 권한이 없음을 알려드립니다.<br><br>
*라이브 오버언더*<br><br>
언오버의 경우 배팅을 어느 시점에 하시건 관계없이 최종스코어가 적용됩니다.<br>
해당 경기에 표기되는 점수는 회원님께서 선택하심에 있어 현재 점수를 알려드리는 것 뿐이지 실제 적용되는 스코어는 아닙니다.<br><br>
각각의 배팅사의 경우 약간씩의 배팅방법이나 규정 등이 조금씩 바뀌기도 하고 다르게 운영되기도 합니다.<br>
배팅을 하시기전 애매한 부분이 있으시면 각 배팅사별로 규정과 가이드가 있으니 참고하시기 바랍니다.<br><br>
상기 내용을 필히 숙지하여 주시기 바라며 이에 따른 불이익을 받지 않도록 이용부탁드립니다.<br><br>
항상 최선을 다하는 IKEA 이 되도록 노력하겠습니다.<br><br>
감사합니다.`,

  browserNoticeTitle: '5. 사이트 이용시 화면이 깨질 경우 구글크롬 공지',
  browserNoticeContent: `안녕하세요 IKEA 입니다. <br>
해외 사이트 이용시 가장 최적화된 브라우저는 [구글 크롬]입니다. <br>
[구글 크롬]은 가볍고 반응속도가 빠르며, [인터넷 익스플로어]와 연동이 가능하고 <br>
속도 측면에서도 훨씬 빠름으로 보다 원활하게 이용이 가능합니다. <br>
단, [크롬]은 은행사 마다 인터넷 뱅킹을 지원하지 않을 수 있으므로 인터넷 뱅킹 이용시에는 <br>
[인터넷 익스플로러]를 이용하는 것을 추천합니다. <br>
<br>
그리고 현재 구글 크롬 브라우저를 사용중이시라면 <br>
인터넷 히스토리 삭제 후 모든 인터넷 창을 닫고 새로 여시기 바랍니다. <br>
<br>
크롬 다운로드 바로가기 : https://www.google.com/intl/ko/chrome/browser/desktop/index.html <br>
<br>
위 링크로 이동하여 Chrome 다운로드를 클릭하면 설치가 진행 되오니 참고 부탁드립니다. <br>
<br>
상기 내용을 필히 숙지하여 주시기 바라며 이에 따른 불이익을 받지 않도록 이용부탁드립니다. <br>
<br>
항상 최선을 다하는 IKEA 이 되도록 노력하겠습니다. <br>
감사합니다.`,

  bettingRegulationTitle: '6. 일부 배팅 방식 규제 안내',
  bettingRegulationContent: `안녕하세요. IKEA 입니다. <br>
<br>
[제재 대상] <br>
▷ 동일 경기 홈과 원정팀에 -, + 핸디캡 동시 배팅 (기준점이 다른 경우 역시 제재 대상) <br>
▶ 동일 경기 언더, 오바 동시 배팅 <br>
▷ 동일 경기 홈과 원정팀에 승, 패 동시 배팅 (무승부 배팅은 가능) <br>
<br>
※ 그 외, 동일 경기에 홈과 원정팀 대상 반대 성향의 배팅을 진행한 것으로 판단될 경우, <br>
동시간대가 아닌, 시간차를 두고 반대 유형 배팅을 성립한 경우 역시 제재 대상이오니 <br>
동일 경기에 대한 배팅 진행 시 반드시 신중한 배팅을 진행해 주시기 바랍니다. <br>
<br>
양방 배팅의 수입 구조가 아니라도, 반대 성향 배팅을 하신 경우 <br>
이유 불문하고 규정대로 제재 조치가 진행되는 사항이오니 이점 참고해 주시기 바랍니다. <br>
<br>
동일 배팅 사이트 뿐만 아니라, 회원님의 전체 배팅사이트 내 아이디의 배팅건에 대해 모두 모니터링 되며, <br>
저희 IKEA 뿐만 아니라 오즈 계열사 내 모든 배팅건에 대해 일괄 모니터링 되오니 <br>
오즈 계열 내에서 동일경기에 대한 양쪽 배팅을 전면 금지해 주시기 바랍니다. <br>
<br>
[제재 조치] <br>
▶ 보유금액 전액 몰수 및 회원 탈퇴 진행 <br>
<br>
저희 IKEA 은 저희 당사를 이용해주시는 모든 회원님 한분 한분을 소중하게 생각하며 <br>
일부 비정상적인 배팅을 진행하는 소수 유저들로부터 정상적인 스포츠 배팅을 즐기시는 <br>
대다수의 회원님들을 보다 안전하고 즐겁게 이용하실 수 있도록 올바른 배팅문화 정착에 <br>
앞장설 것을 약속 드리겠습니다.`,

  bettingGuideTitle: '7. 축 베팅 및 보험베팅 관련 안내',
  bettingGuideContent: `안녕하세요. IKEA 입니다. <br>
<br>
안녕하세요. 저희는 회원분들께 축배팅, 중복배팅, 보험배팅 모두 상한가 금액안에서 <br>
자유롭게 이용 가능합니다. <br>
ex) 1레벨의 경우 당첨상한가 2,000만원이라고 가정하고 <br>
<br>
A+B+C = 600 배팅하고, A+B+D=1,000 배팅 시 당첨금 1,600만원 입니다. 해당 배팅은 정상적인 배팅입니다. <br>
<br>
A+B+C = 1,000 배팅하고, A+B+D=1,400 배팅 시 당첨금 2,400만원 입니다. 해당 배팅은 상한가 초과배팅 이므로 차액 만큼 몰수처리 됩니다. <br>
<br>
악의적인 배팅을 하시려는 유저분께서는 <br>
미리 몰수 처리되실 것을 감안하시고 배팅하시기 바랍니다. <br>
<br>
항상 최선을 다하는 IKEA 이 되도록 노력하겠습니다. <br>
감사합니다.`,

  depositInquiryTitle: '8. 타인명의로 입금 가능여부 안내',
  depositInquiryContent: `▶ 타인명의로 입금 가능여부 문의 안내 ◀ <br>
<br>
안녕하세요. IKEA 입니다. <br>
<br>
IKEA 에서는 악용의 여지를 남기지 않기 위해 본인명의 등록계좌를 기준으로 충전 및 환전 서비스를 진행하고 있습니다. <br>
<br>
오직 본인명의 등록계좌 로만 입금 해주셔야 하며, 타인명의로 입금시 환불절차를 통해 다시금 송금해드리고 있습니다. <br>
<br>
환불절차에 필요한 사항으로 입금하신 금액, 시간, 계좌, 명의 기재 해주셔야하며 환불절차는 확실한 확인이 필요함으로 다소 시간이 지연될 수 있는 점 참고바랍니다. <br>
<br>
상기 내용을 필히 숙지하여 주시기 바라며 이에 따른 불이익을 받지 않도록 이용부탁드립니다. <br>
<br>
항상 최선을 다하는 IKEA 이 되도록 노력하겠습니다. <br>
감사합니다.`,

  sportsResultTitle: '9. 스포츠 및 라이브 결과처리 안내',
  sportsResultContent: `안녕하세요 IKEA 입니다. <br>
<br>
저희는 해외 메이저급 BT1 API를 연동하여 다양한 경기 및<br>
배당 그리고 판정 결과를 연동해서 운영하고 있습니다.<br>
하나의 경기에 다양한 마켓이 존재하며 경기의 결과 처리가 다소 늦어질 수 있는 점<br>
양해 부탁드립니다.<br>
<br>
스포츠의 결과 처리는 기본적으로 경기 종료 후 경기 결과가 반영됩니다.<br>
실시간 스포츠의 경우는 전후반, 이닝, 쿼터, 피리어드 등에 배팅 시 해당 이닝, 쿼터가 종료되면 경기 결과가 반영됩니다.<br>
<br>`,

  generalRulesTitle: '4. IKEA 스포츠 규정',
  generalRulesContent: `1.1 일반<br>
이 규칙은 2003년 8월부터 유효합니다. IKEA에 의해 승인된 이 규칙들에 의해 변동될 수 있습니다.<br>
베팅 계좌를 열길 원하는 사람은 만 18세 이상이어야 합니다. 지역이나 국가의 법들의 승인(market에 등록하거나 인터넷을 통한
베팅 전에 그 지역에 효력을 발생하는 게임에 대한 법들에 따라)에 대한 각 고객들의 무조건적인 책임입니다.<br>
IKEA와 함께 베팅을 하길 원하는 고객들은 그들의 나라, 그들이 주거하는 곳이나, 혹은 지금 현재 머무르고 있는 곳에서
존재하는 베팅행위를 금지하는 특별한 법이 있을 수 있다는 사실을 알고 계셔야 합니다. 고객들은 반드시 언제든 적절한 지역 법에
따라야 하고 만약 어떤 궁금한 점이 있으면 그 지역의 법적 조언을 구하셔야 합니다. IKEA는 어떠한 지역의 겜블이나 베팅의
법에 따른 책임을 질 수 없습니다. 타이핑으로 인한 오류나 사람이 저지르는 오류가 발생하는 경우 본사는 확률의 실수를 수정하거나
계정들을 정확한 확률로 고정시키는 권리를 보유하고 있습니다. 본사는 베팅의 모든 부분에 대해서 승낙과 거절의 절대적인 결정권을
가지고 있습니다. 고객님께서 다른 스포츠 북과 다른 비영어 이름이나 팀 이름에 궁금한 것이 있다면, 최종 참고사항인 “영어” 버전으로
조회하시기 바랍니다.<br><br>
1.2 계정<br>
저희 베팅 서비스에 접속을 원하신다면 고객은 먼저 계정을 만드셔야 합니다.<br>
고객들은 그들의 계정에 명시되어 있는 베팅의 한계까지 베팅이 가능할 것입니다.<br>
고객은 그들의 계정 번호와 비밀번호에 대한 비밀의 유지에 책임을 가지고 있습니다.<br>
어떠한 고객의 계정의 온라인 베팅에도 효력을 가지고 있습니다.<br>
고객께서 개인정보가 유출되었다고 의심되시면 즉시 세부정보의 변경을 위해 저희에게 알려주십시오.<br>
고객들은 지불이 확인된 그들의 계정에서 언제든지 인출이 가능할 것입니다.<br>
회사는 이유의 설명 없이 언제든지 고객의 정지에 대한 권리를 가지고 있습니다.<br>
이러한 경우에 고객의 게정에 남아있는 잔액은 고객에게 되돌아가게 될 것입니다.<br><br>
1.3 수락 절차<br>
만약 어떠한 고객의 계좌의 예금의 합계가 부정확하다면, 회사에 알려주시는 것은 고객의 책임입니다.<br>
그렇지 않으면, 이 실수에 따라 직접 혹은 간접적으로 발생되는 모든 베팅들은 취소될 것입니다.<br><br>
1.4 베팅 수락<br>
저희는 온라인이나 전화로 만들어지는 베팅만을 수락합니다.<br>
베팅의 세부사항이 확실한지 확인하는 것은 고객의 책임입니다.<br>
베팅이 이루어지고 수락이 확인되고 나면 고객은 더 이상 취소나 변경을 할 수 없습니다.<br>
우리는 언제든 마켓을 연기할 수 있는 권리를 가지고 있습니다.<br>
마켓이 연기될 때에는 어떠한 베팅이든 거부될 것입니다.<br>
우리는 사전에 공지 없이 어떠한 마켓에서의 베팅의 중지에 대한 권리를 가지고 있습니다.<br>
모든 승리는 고객의 계정에 기록됩니다.<br>
오류로 인해 계정에 기록된 승리는 사용이 가능하지 않고 그러한 경우에 대한 금액의 송금을 취소할 권리를 가지고 있습니다.<br>
우리는 정보통신장비의 문제 때문에 생기는 베팅의 실패에 대한 책임을 지지 않습니다.<br>
'1.5 베팅 규칙<br>
  모든 고객은 인터넷 연결이 절대적으로 안정적이지 않다는 것을 알고 있어야 합니다.<br>
    모든 베팅은 저희의 시스템의 베팅시스템의 베팅레코드에 저장되게 됩니다.<br>
    만약 여러분이 어떤 베팅을 하셨는지 확실히 모르시겠다면 즉시 저희 고객 서비스로 문의를 주셔야 합니다.<br>
    IKEA는 승산에 대한 분석과 발표에 실수가 없도록 최선을 다하고 있습니다.<br>
    하지만 예상치 못한 실수가 발생한다면 영향을 받은 베팅의 무효나 에러의 수정에 대한 권리를 가지고 있습니다.<br>
    또한 IKEA는 어떤 사람이나 그룹이 일제히 담합 혹은 연합해서 저지르게 되는 사기 행각에 대해 발생된 손실에 대해 손해배상을 청구할 권리를 가지고 있습니다.<br>
    ‘잘못된 행위 그리고 사기행위‘ 는 규칙과 규정을 어기기 위한 시도, 한 사람당 하나의 계정, 베팅의 한계, 승리의 한계, 해킹 허위 개인정보, 어떠한 행동 혹은 생량 등을 포함하며, IKEA는 위와 같은 행위를 잘못된 행위 혹은 사기행위로 간주할 것입니다.<br>
    IKEA와 함께 베팅과 겜블을 하고 싶은 고객 분들은 살고 있는 혹은 현재 머무르는 각 나라의 정해진 도박이나 게임에 대한 규제의 관련법규에 주의하셔야 합니다.<br>
    고객은 적절한 지역법규에 분명히 따라야 하고 의문이 있을 경우 그 지방의 법적인 조언을 받아야 합니다.<br>
    IKEA는 어떤 지역의 도박이나 베팅의 법규에 대한 책임을 지지 않습니다.<br>
  2. 스포츠 베팅 규칙<br>
  2.1 보통 규칙<br>
      이벤트의 승자는 이벤트의 결과가 있었던 날에 결정될 것입니다.(어떤 특별한 스포츠 규칙이 명시되어 있지 않는다면).<br>
      돈을 거는 목적으로 이의가 제기되거나 결정이 바뀌는 것은 인정되지 않습니다.<br>
      만약 게임이나 이벤트가 연기되고, 12시간 후에 재개되지 않는다면(어떤 특별한 스포츠 규칙이 명시되어 있지 않는다면) 모든 베팅은 환불되게 될 것입니다.<br>
      모든 베팅은 게임종료 후 72시간 후에 결정됩니다.<br>
      IKEA는 그 후에 생기는 어느 변화에도 관여하지 않습니다.<br>
      제공된 게임 통계는 단지 참고용입니다.<br>
      2.2 취소되고 연기된 경기들<br>
      취소되거나 연기된 경기들에 대한 내기의 결과는 본사 단독의 결정권에 달려있고, 본사의 결정이 최종 결과가 될 것입니다.<br>
      2.3 아웃라이트 베팅에 대한 일반적 규칙들<br>
      경기에 참가하지 않은 경우에는 환불이 없습니다.<br>
      다승자 법칙이 모든 아우라이트 베팅에 적용됩니다.<br>

3축구<br>
모든 확률이나 핸디캡-골은 변동될 수 있습니다.<br>
그리고 베팅은 베팅이 이루어지고 확인되는 그 시간에 우세함에 따라 고정될 것입니다.<br>
모든 축구경기 베팅은 다른 공지사항이 없다면 인저리, 로스타임 포함하여 전후반 45분 경기의 결과에 따라 결정됩니다.<br>
이것은 엑스트라 골든 골 그리고 승부차기 혹은 경기 후에 심판이나 공식위원회에 의해 변경되는 경기결과는 포함하지 않습니다.<br>
우연히 어떤 토너먼트나 친선경기가 2x40분 동안 경기 경기할 예정이라면 모든 베팅은 예정된 시간이 끝나야 확립될 것입니다.<br>
플레잉 타임이 2x35분이나 더 적은 청소년이나 친선 경기가 있을 것을 겁니다.<br>
IKEA는 게임시작 전에 이러한 게임에 대해 알려드릴 것입니다.<br>
그렇지 않으면 모든 축구경기 베팅은 무효로 간주될 것입니다.<br>
만약 게임이 중지되었거나 연기되면 킥오프로부터 12시간 안에 재개되지 않는다면 모든 베팅은 환불될 것입니다.(특별란 스포츠규정이 있지 않는 한)<br>
만약 게임이 정해진 시간 전에 시작된다면 게임시작 전까지 베팅한 모든 사람까지만 유효한 것으로 간주될 것입니다.<br>
구체적인 베팅의 종류에 따라 베팅의 최대 액수의 양이 변하게 됩니다.<br>
CORRECT SCORE UP5를 이기기 위해서 여러분이 선택한 팀(홈 혹은 어웨이)는 5골 이상의 차이로 이겨야 합니다.<br>
경기장소의 변화의 경우에(홈팀이 어웨이에서 플레이하거나 그 반대의 경우). 매치에 관련된 모든 베팅은 무효가 됩니다.<br>
만약 매치가 중립구장에서 플레이 하도록 되어있는 경우에는(그러나 중립구장이 아닌 곳에서 플레이 되거나 그 반대의 경우) 모든 베팅 은 유효 합니다.<br>
라이브 경기에 대한 시간이나 레드카드 고지는 기준에 따라 적용합니다.<br>
페널티 핸디캡 결과는 서든데스를 포함합니다.<br>
페널티 오버/언더 결과는 서든데스를 포함하지 않습니다.<br>
3.1 첫 번째 옐로우 카드<br>
지정된 매치에서 첫 번째 옐로우 카드를 받는 팀을 예측<br>
만약 첫 번째 예약이 바로 레드카드(프로페셔널 파울)이면, 옐로카드가 아닌 레드카드로 간주됩니다.<br>
이벤트에서 둘 혹은 이상의 선수가 사고로 관여했을 경우 심판이 첫 번째 옐로카드를 든 선수가 "승자"로 간주됩니다.<br>
출장하지 않은 선수(예를 들어 후보/리저브, 매니저, 코치)가 받은 옐로카드는 포함되지 않습니다.<br>
만약 게임이 첫 옐로카드를 받은 후에 중단된다면 첫 번째 옐로카드의 베팅은 모두 정지됩니다.<br>
위에서 언급하지 않은 다른 모든 룰은 축구 베팅 룰을 따릅니다.<br>
3.2 총 기입<br>
총 기입 결과는 홈/어웨이 팀에 의해 누적된 기입량의 수로 결정됩니다(핸디캡을 포함하여).<br>
모든 결정은 공식 웹사이트/주최자에 의한 공식적인 결과에 기초합니다.<br>

3.3 예약 포인트<br>
1개의 옐로우 카드 = 1 point<br>
1개의 레드 카드 = 2 point<br>
선수당 세는 최대 포인트는 3점입니다.<br>
첫 번째 옐로우 카드는 1점 그리고 레드카드는 2점 두 번째 옐로우 카드는 세지 않습니다.<br>
만약 경기가 공식적인 90분 이내에 무효가 된다면 전체 예약(Total Booking)에 걸린 모든 베팅은 환불됩니다.<br>
위에서 언급하지 않은 모든 룰은 사커 룰을 따릅니다.<br>
3.4 첫번째 코너<br>
지정된 게임에서 첫 번째 코너킥을 받는 팀을 예측하여라.<br>
만약 게임이 첫 번째 코너킥에 주어진 후 게임이 종료된다면 첫 번째 코너킥에 대한 모든 베팅은 유효합니다.<br>
만약 게임이 첫 번째 코너킥이 주어지기 전에 종료된다면 첫번째 코너킥에 대한 모든 베팅은 환불됩니다.<br>
위에서 언급하지 않은 모든 룰은 사커 룰을 따릅니다.<br>
3.5 마지막 코너<br>
지정된 공식 90분의 경기에서 마지막 코너킥을 받은 팀을 예측하여라.<br>
만약 공식 90분 전에 게임이 종료된다면 마지막 코너킥에 대한 모든 베팅은 환불됩니다.<br>
위에서 언급하지 않은 모든 룰은 사커 룰을 따릅니다.<br>
3.6 총 코너<br>
코너 결과에 대한 수는 지정된 경기(핸디캡 포함)홈/어웨이 팀의 코너의 총 합계로 결정됩니다.<br>
공식 웹사이트/오거나이저에 의해 공식 결과가 표시됩니다.<br>
위에서 언급하지 않은 모든 룰은 사커베팅룰을 따릅니다.<br>
3.7 선수교체 수의 총 합계<br>
선수교체의 총 합계는 홈/어웨이 팀 선수교체 총 합계에 의해 결정됩니다.<br>
공식 웹사이트/오거나이저에 의해 공식 결과가 표시됩니다.<br>
만약 공식 90분 전에 게임이 종료된다면 선수교체에 대한 모든 베팅은 환불됩니다.<br>
위에서 언급하지 않은 모든 룰은 사커베팅룰을 따릅니다.<br>
3.8 첫 번째/마지막 선수교체<br>
지정된 공식 90분의 경기에서 첫 번째/마지막 선수교체를 한 팀을 예측하여라.<br>
공식 웹사이트/오거나이저에 의해 공식 결과가 표시됩니다.<br>
같은 시간에 2명의 선수가 교체를 하고 있다면, 공식적으로 네명 중 첫 번째로 보인 플레이어가 “승자” 로 간주됩니다.<br>
만약 첫 번째 선수교체가 진행된 후에 게임이 종료된다면 첫 번째 베팅에 대한 모든 베팅은 유효합니다. 그러나 마지막 선수교체에 대한 모든 베팅은 환불됩니다.<br>
만약 어떤 선수교체 후에 게임이 종료된다면 첫번째 선수교체와 마지막 선수교체에 대한 모든 베팅은 환불됩니다.<br>
위에서 언급하지 않은 모든 룰은 사커베팅룰을 따릅니다.<br>

3.9 총 오프사이드<br>
지정된 공식 90분 동안 총 오프사이드는 유효하고 공식 웹사이트/오거나이저에 의해 결과가 유효합니다.<br>
만약 공식 90분 전에 게임이 종료된다면 총 오프사이드에 대한 모든 베팅은 환불됩니다.<br>
위에서 언급하지 않은 모든 룰은 사커 베팅 룰을 따릅니다.<br>
3.10 첫번째/마지막 오프사이드2. 스포츠 베팅 규칙<br>
2.1 보통 규칙<br>
이벤트의 승자는 이벤트의 결과가 있었던 날에 결정될 것 입니다.(어떤 특별한 스포츠 규칙이 명시되어 있지 않는다면).<br>
돈을 거는 목적으로 이의가 제기되거나 결정이 바뀌는 것은 인정되지 않습니다.<br>
만약 게임이나 이벤트가 연기되고, 12시간 후에 재개되지 않는다면(어떤 특별한 스포츠 규칙이 명시되어 있지 않는다면)<br>
모든 베팅은 환불되게 될 것입니다.<br>
모든 베팅은 게임종료 후 72시간 후에 결정됩니다.<br>
IKEA는 그 후에 생기는 어느 변화에도 관여하지 않습니다.<br>
제공된 게임 통계는 단지 참고용입니다.<br>
2.2 취소되고 연기된 경기들<br>
취소되거나 연기된 경기들에 대한<br>
내기의 결과는 본사 단독의 결정권에 달려있고, 본사의 결정이 최종 결과가 될 것입니다.<br>
2.3 아웃라이트 베팅에 대한 일반적 규칙들<br>
경기에 참가하지 않은 경우에는 환불이 없습니다.<br>
다승자 법칙이 모든 아우라이트 베팅에 적용됩니다.<br>

3. 축구<br>
모든 확률이나 핸디캡-골은 변동될 수 있습니다.<br>
그리고 베팅은 베팅이 이루어지고 확인되는 그 시간에 우세함에 따라 고정될 것입니다.<br>
모든 축구경기 베팅은 다른 공지사항이 없다면 인저리, 로스타임 포함하여 전후반 45분 경기의 결과에 따라 결정됩니다.<br>
이것은 엑스트라 골든 골 그리고 승부차기 혹은 경기 후에 심판이나 공식위원회에 의해 변경되는 경기결과는 포함하지 않습니다.<br>
우연히 어떤 토너먼트나 친선경기가 2x40분 동안 경기 경기할 예정이라면 모든 베팅은 예정된 시간이 끝나야 확립될 것입니다.<br>
플레잉 타임이 2x35분이나 더 적은 청소년이나 친선 경기가 있을 것을 겁니다.<br>
IKEA는 게임시작 전에 이러한 게임에 대해 알려드릴 것입니다.<br>
그렇지 않으면 모든 축구경기 베팅은 무효로 간주될 것입니다.<br>
만약 게임이 중지되었거나 연기되면 킥오프로부터 12시간 안에 재개되지 않는다면 모든 베팅은 환불될 것입니다.(특별란 스포츠규정이 있지 않는 한)<br>
만약 게임이 정해진 시간 전에 시작된다면 게임시작 전까지 베팅한 모든 사람까지만 유효한 것으로 간주될 것입니다.<br>
구체적인 베팅의 종류에 따라 베팅의 최대 액수의 양이 변하게 됩니다.<br>
CORRECT SCORE UP5를 이기기 위해서 여러분이 선택한 팀(홈 혹은 어웨이)는 5골 이상의 차이로 이겨야 합니다.<br>
경기장소의 변화의 경우에(홈팀이 어웨이에서 플레이하거나 그 반대의 경우). 매치에 관련된 모든 베팅은 무효가 됩니다.<br>
만약 매치가 중립구장에서 플레이 하도록 되어있는 경우에는(그러나 중립구장이 아닌 곳에서 플레이 되거나 그 반대의 경우) 모든 베팅 은 유효 합니다.<br>
라이브 경기에 대한 시간이나 레드카드 고지는 기준에 따라 적용합니다.<br>
페널티 핸디캡 결과는 서든데스를 포함합니다.<br>
페널티 오버/언더 결과는 서든데스를 포함하지 않습니다.<br>
3.1 첫 번째 옐로우 카드<br>
지정된 매치에서 첫 번째 옐로우 카드를 받는 팀을 예측<br>
만약 첫 번째 예약이 바로 레드카드(프로페셔널 파울)이면, 옐로카드가 아닌 레드카드로 간주된다.<br>
이벤트에서 둘 혹은 이상의 선수가 사고로 관여했을 경우 심판이 첫 번째 옐로카드를 든 선수가 "승자"로 간주됩니다.<br>
출장하지 않은 선수(예를 들어 후보/리저브, 매니저, 코치)가 받은 옐로카드는 포함되지 않습니다.<br>
만약 게임이 첫 옐로카드를 받은 후에 중단된다면 첫 번째 옐로카드의 베팅은 모두 정지된다.<br>
위에서 언급하지 않은 다른 모든 룰은 축구 베팅 룰을 따른다.<br>

3.2 총 기입<br>
총 기입 결과는 홈/어웨이 팀에 의해 누적된 기입량의 수로 결정된다(핸디캡을 포함하여).<br>
모든 결정은 공식 웹사이트/주최자에 의한 공식적인 결과에 기초한다.<br>
어떤 플레이어(예 : 후보 선수,메니저, 코치)도 받지 않았다면 합계에 합산하지 않는다.<br>
3.3 예약 포인트<br>
1개의 옐로우 카드 = 1 point<br>
1개의 레드 카드 = 2 point<br>
선수당 세는 최대 포인트는 3점이다.<br>
첫 번째 옐로우 카드는 1점 그리고 레드카드는 2점 두 번째 옐로우 카드는 세지 않는다.<br>
만약 경기가 공식적인 90분 이내에 무효가 된다면 전체 예약(Total Booking)에 걸린 모든 베팅은 환불된다.<br>
위에서 언급하지 않은 모든 룰은 사커 룰을 따른다.<br>
3.4 첫번째 코너<br>
지정된 게임에서 첫 번째 코너킥을 받는 팀을 예측하여라.<br>
만약 게임이 첫 번째 코너킥에 주어진 후 게임이 종료된다면 첫 번째 코너킥에 대한 모든 베팅은 유효하다.<br>
만약 게임이 첫 번째 코너킥이 주어지기 전에 종료된다면 첫번째 코너킥에 대한 모든 베팅은 환불된다.<br>
위에서 언급하지 않은 모든 룰은 사커 룰을 따른다.<br>
3.5 마지막 코너<br>
지정된 공식 90분의 경기에서 마지막 코너킥을 받은 팀을 예측하여라.<br>
만약 공식 90분 전에 게임이 종료된다면 마지막 코너킥에 대한 모든 베팅은 환불된다.<br>
위에서 언급하지 않은 모든 룰은 사커 룰을 따른다.<br>
3.6 총 코너<br>
코너 결과에 대한 수는 지정된 경기(핸디캡 포함)홈/어웨이 팀의 코너의 총 합계로 결정된다.<br>
공식 웹사이트/오거나이저에 의해 공식 결과가 표시된다.<br>
위에서 언급하지 않은 모든 룰은 사커베팅룰을 따른다.<br>

3.7 선수교체 수의 총 합계<br>
선수교체의 총 합계는 홈/어웨이 팀 선수교체 총 합계에 의해 결정된다.<br>
공식 웹사이트/오거나이저에 의해 공식 결과가 표시된다.<br>
만약 공식 90분 전에 게임이 종료된다면 선수교체에 대한 모든 베팅은 환불된다.<br>
위에서 언급하지 않은 모든 룰은 사커베팅룰을 따른다.<br>
3.8 첫 번째/마지막 선수교체<br>
지정된 공식 90분의 경기에서 첫 번째/마지막 선수교체를 한 팀을 예측하여라.<br>
공식 웹사이트/오거나이저에 의해 공식 결과가 표시된다.<br>
같은 시간에 2명의 선수가 교체를 하고 있다면, 공식적으로 네명 중 첫 번째로 보인 플레이어가 “승자” 로 간주된다.<br>
만약 첫 번째 선수교체가 진행된 후에 게임이 종료된다면 첫 번째 베팅에 대한 모든 베팅은 유효하다. 그러나 마지막 선수교체에 대한 모든 베팅은 환불된다.<br>
만약 어떤 선수교체 후에 게임이 종료된다면 첫번째 선수교체와 마지막 선수교체에 대한 모든 베팅은 환불된다.<br>
위에서 언급하지 않은 모든 룰은 사커베팅룰을 따른다.<br>
3.9 총 오프사이드<br> 지정된 공식 90분 동안 총 오프사이드는 유효하고, 공식 웹사이트/오거나이저에 의해 결과가 유효하다.<br> 만약 공식 90분 전에 게임이 종료된다면 총 오프사이드에 대한 모든 베팅은 환불된다.<br> 위에서 언급하지 않은 모든 룰은 사커 베팅 룰을 따른다.<br>
3.10 첫 번째/마지막 오프사이드<br> 지정된 공식 90분 동안 첫 번째/마지막 오프사이드 한 팀을 예측하여라.(연장전은 제외)<br> 베팅은 공식 웹 사이트에 의한 결과에 기초한다.<br> 만약 첫 번째 오프사이드 후에 게임이 종료된다면 첫 번째 오프사이드에 대한 모든 베팅은 유효하다. 그러나 마지막 오프사이드에 대한 모든 베팅은 환불된다.<br> 만약 게임이 어떤 오프사이드라도 생기기 전에 종료된다면 첫 번째/마지막 오프사이드에 대한 모든 베팅은 환불된다.<br> 위에서 언급하지 않은 모든 룰은 사커 베팅 룰을 따른다.<br>
3.11 페널티 킥<br> 지정된 공식 90분 안에 받거나 내준 페널티의 숫자를 예측하라.<br> 만약 페널티를 받거나 내준 후에 게임이 종료된다면 모든 베팅은 유효하다.<br> 만약 페널티를 받거나 내주기 전에 게임이 종료된다면 모든 베팅은 환불된다.<br>
농구<br> 정해진 스케줄에 따라 시작된 게임 전에 정해진 베팅은 유효하다.<br> 그렇지 않은 경우는 무효하다.<br> 베팅에 대한 처리가 이미 결정되지 않았다면, 만약 게임이 43분에 종료되지 않거나 (NBA, 35분에 종료되지 않거나 (WNBA/NCAA/EUROBASKET)), 그리고 다른 게임에서 정규 시간 안에 끝나지 않는다면 베팅은 무효가 된다.<br> 따로 명시가 되어 있지 않은 한, 베팅은 오버타임을 포함하여 공식적인 파이널 스코어를 기초로 한다.<br> 전반전에 대한 베팅에 대한 처리는 첫 번째 그리고 두 번째 퀴터의 점수를 더한 것이다.<br> 후반전에 대한 베팅에 대한 처리는 세 번째 그리고 네 번째 퀴터의 점수를 더한 것이다. (필요하다면 오버타임 포함)<br> 네 번째 쿼터에 대한 베팅에 처리는 제외된다.<br>
4.1 농구 (라이브 게임)<br> 처리는 오버타임을 포함한 공식 최종 결과에 기초한다.<br> 우리가 보여주는 스코어는 무시하시기 바랍니다.<br> First basket을 기록할 팀에 대한 처리는 게임에서 첫 득점을 기록한 팀을 기준으로 한다.<br> Last basket을 기록할 팀에 대한 처리는 따로 명시가 없다면 오버타임을 포함하여 게임에서 마지막 득점을 기록한 팀을 기준으로 한다.<br> 선수에 의한 총 점수/리바운드/어시스트:<br> 특정 게임에서의 선수의 통계에 기초한다.<br> 베팅이 성립되기 위해서는 게임이 반드시 완료되어야 한다.<br> 베팅은 비 주전선수에 대해선 환불될 것이다.<br> 득점을 올리거나 리바운드, 어시스트를 하지 못한 선수에 관한 베팅은 그 선수가 0점을 기록한 것으로 간주된다.<br>

그 외의 종목들<br>
5.1 미국 풋볼<br> 정해진 스케줄에 따라 시작된 게임 전에 정해진 베팅은 유효하다. 그렇지 않은 경우는 무효하다.<br> 게임이 적어도 55분에 종료되지 않거나, 게임이 재시작하지 않는다면, 혹은 베팅에 대한 처리가 결정되지 않았다면 베팅은 무효로 처리된다.<br> 따로 명시되지 않은 한 베팅은 오버타임을 포함하여 (만약 필요하다면) 공식적인 파이널 스코어를 기초로 한다.<br> 전반전에 대한 베팅에 대한 처리는 첫 번째, 두 번째 퀴터의 점수를 더한 것이다.<br> 후반전에 대한 베팅에 대한 처리는 세 번째, 네 번째 퀴터의 점수를 더한 것이다. (필요하다면 오버타임 포함)<br> 네 번째 쿼터에 대한 베팅에 처리는 제외된다.<br>
5.2 야구<br> 만약 등록된 투수들이 경기를 시작하지 않고 적어도 1개 이상의 공을 던지지 않으면 모든 베팅은 무효다.<br> 결과는 추가 이닝들을 포함한 공식적인 결과에 근거한다. (적용 가능할 경우)<br> 머니라인: 만약 홈팀이 4½ 이닝 이후 이기고 있는 경우를 제외하고 경기가 5이닝을 넘어서지 않았다면 모든 처리는 무효로 간주된다.<br> 라인베팅 (핸디캡, 오버/언더): 만약 홈팀이 8½ 이닝 이후에 이기고 있는 경우를 제외하고, 9이닝까지 경기가 치러지지 않았다면 모든 처리는 무효로 간주된다.<br>
5.3 전반<br> 모든 베팅은 처음 다섯 이닝 후에 스코어를 기반으로 결정된다.<br> 만약 게임이 다섯 이닝을 채우지 못한다면 전반의 베팅은 무효가 될 것이다.<br> 만약 예고된 투수가 선발로 나오지 않는다면 전반의 모든 베팅은 무효가 될 것이다.<br>
5.4 아이스 하키<br> 경기가 3피리어드에서 끝났거나 최소 55분이 플레이 되었다면 모든 거래는 유효한 것으로 간주된다.<br> 만약 무승부 옵션이 포함되었다면, 결과는 보통 시간의 끝에 근거하게 된다.<br> 라인베팅 (핸디캡과 오버/언더): 결과는 추가 시간과 페널티 슛-아웃이 포함되어 결정된다 (적용 가능한 경우).<br> 페널티 슛-아웃: 슛-아웃 경기에서 1골을 그들의 점수에 더한 것이 마지막 경기의 점수로 결정된다.<br> 피리어드 베팅: 모든 거래가 유효하려면 피리어드가 끝나야 한다.<br> 3피리어드 베팅: 점수는 그 피리어드의 추가 시간과 페널티 슛-아웃을 제외한다.<br>
5.5 필드하키<br> 모든 베팅은 보통 시간의 끝에 혹은 심판/위원회에 의해서 변경된 결과에 의해 결정된다.<br> (추가 시간 & 페널티는 명확히 제시되지 않으면 계산되지 않는다.)<br> 모든 라이브 게임 베팅은 70분 이후의 게임 스코어에 의해 정해진다.<br>
전반 베팅 규칙 (35분):<br> 모든 전반 베팅은 전반의 플레이에만 적용된다.<br> 경기가 전반 중에 취소되거나 중지된다면, 모든 전반 베팅은 취소로 간주된다.<br> 경기가 후반 중에 취소되거나 중지된다면, 모든 전반 베팅은 유효한 것으로 간주된다.<br>
5.6 테니스<br> 만약 게임이 중단되거나 연기되고 48시간 내에 다시 진행되지 않는다면 모든 베팅은 환불된다.<br> 경기에 이기기 위한 모든 세트의 숫자는 모두 공식적인 토너먼트 개최자의 정보를 따른다.<br> 베팅은 장소나 코트의 상태와 상관없이 지속된다.<br> 오버/언더: 경기에서 플레이된 모든 게임에 대해 적용된다.<br> 홀수/짝수: 경기에서 플레이된 모든 게임에 대해 적용된다.<br> 첫 번째 세트 승자: 첫 번째 세트 승자는 오직 첫 번째 세트에만 적용된다. 베팅은 첫 번째 세트가 끝난 후 결과를 기준으로 결정된다.<br> 첫 번째 세트가 끝나지 않으면 모든 베팅은 무효로 간주된다.<br>
5.7 배드민턴<br> 만약 선수가 은퇴하거나 자격 상실로 매치가 끝나지 못하였다면 모든 베팅은 무효다.<br> 오버/언더: 매치에서 플레이된 총 포인트<br> 홀수/짝수: 매치에서 플레이된 총 포인트<br>
5.8 탁구<br> 경기가 선수의 은퇴나 자격 박탈의 이유로 끝나지 않는다면, 모든 단독 베팅 (1대1 포함) 및 오버/언더 베팅은 무효가 된다.<br> 결과는 경기 직후 발표된 공식적인 결과에 의해서만 결정된다. 차후 항의는 인정되지 않는다.<br
매치 베팅 <br>
매치 핸디캡 - 핸디캡은 게임이 끝날 때 정해집니다. <br>
만약 첫 번째 공격팀이 게임에서 이기게 되면，RUN HANDICAP에 의해 정해집니다. <br>
만약 두 번째 공격팀이 게임에서 이기게 되면，WICKET HANDICAP에 의해 정해집니다. <br>
첫 번째 배트맨/볼러 선발 11명에 포함되지 않은(‘슈퍼서브매치' 에서 명기되지 않은 후보)배트맨/볼러에 대한 모든 베팅은 무효이다. <br>
만약 선발 11명에 포함 된 ( ‘슈퍼서브’ 매치에 포함된) 선수가 베팅이나 투구하지 않는다면 선수는 0점율 스코어 한 것으로 간주합니다. <br>
베트맨/볼러 - 헤드 투 헤드 <br>
베트맨 헤드 투 헤드 <br>
베팅상태에서 불이 투구되었을 때 두 베트맨은 반드시 타석에 있어야 한다. 그렇지 않으면 베팅은 무효이다. <br>
불러 헤드 투 헤드 <br>
베팅상태에서 두 명의 볼러는 반드시 적어도 한 개의 불은 투구해야 한다. 그렇지 않으면 베팅은 무효이다. <br>
하이스트 오프닝 파트너쉽 <br>
모든 베팅은 오프닝 파트너쉽에 토탈 런(Total Run)에 기초한다. <br>
연속베팅 <br>
만약 지정된 수의 매치가 완료되지 않거나 베팅이 이미 결정되지 않았다면 모든 베팅은 무효이다. <br>
메치 삭스(6s):- <br>
만약 게임이 한 over 안에서 게임이 취소되거나 단축될 경우 모든 베팅은 무효이다. <br>
만약 게임이 한 over 안에서 단축되고 게임결과가 나오면 가장 over 에 상관없이 six hit을 낸 팀이 승자로 걸정된다. <br>
핸디캡과 오버/언더에 관한 사항은 한 게임의 최고 6장을 기록한 양팀에 기초합니다. <br>
테스트 매치 <br>
만약 공식 사무관이 승자를 결정하지 않거나 무승부를 선언한다면, 모든 베팅은 무효이다. <br>
만약 게임이 날씨나 어떤 딜레이에 의해 영향을 받는다면 모든 베팅은 무효이다. <br>
제한된 오버 매치 <br>
만약 게임이 연기되거나, 36시간 이전에 재진행 되지 않는다면 모든 베팅은 무효입니다. <br>
Duckworth Lewis 룰이 구현되기 전에 마켓이 비 조건적으로 결정되지 않는 한 Duckworth Lewis 룰을 사용하는 곳에서 <br>
"Highest 10 over Total" 과 "Opening Partnership" 과 관련된 모든 베팅은 무효이다. <br>
하루 경기에서 만약 게임이 날씨나 어떤 이유에 의해 빨리 끝난다면, 모든 베팅은 공식적인 룰에 따른다. <br>
만약 최종 결과가 볼 아웃(bowl out)이나 동전 던지기(the toss of coin) 에 의해 결정된다면 모든 베팅은 무효로 간주된다. <br>
만약 예정된 경기 날에 새로운 동전던지기(the toss of coin)가 진행된다면 원래 첫날에 시작하는 스케줄로부터 45분 동안 시작된 베팅은 무효이다. <br>
비 조건적으로 결정된 것 을 제외하고(Win the Toss) 이 룰은 모든 마켓과 관계가 있다. <br>

5.11 배 구 <br>
오버/언더 - 경기에서 플레이 된 총 점수 홀수-짝수 - 경기에서 플레이 된 총 점수 <br>
5.12 핸드볼 <br>
모든 베팅은 보통 시간의 마지막에 결정되게 될 것입니다. (추가 시간은 계산되지 않습니다) <br>
5.13 비치 사커 <br>
특별한 언급이 없다면 모든 베팅은 3x12분의 경기 결과가 결정되어 집니다. <br>
만약 게임이 정해진 시간 전에 시작한다면 모든 베팅은 무효로 간주되어 집니다. (live 베팅은 제외) <br>
5.14 풋살 <br>
특별한 언급이 없다면 모든 베팅은 2x20분의 경기 결과가 결정되어 집니다. <br>
추가 시간과 페널티 슛 아웃은 정규 게임시간에 포함되지 않습니다. <br>
만약 게임이 정해진 시간 전에 시작한다면 모든 베팅은 무효로 간주되어 집니다. (live 베팅은 제외) <br>
5.15 당구&스누커 <br>
베팅이 유지되려면, 두 명의 선수가 함께 경기를 시작하고 마지막까지 끝마쳐야 합니다. <br>
경기의 시작의 경우에 첫 번째 프레임의 브레이크 - 하지만 어떤 이유 때문에 끝나지 않았다면, 더 이상의 플레이가 결과에 영향을 <br>
미치지 않는 한, 모든 베팅은 무효로 될 것 입니다. <br>
5.16 럭비 <br>
따로 공지와는 것이 없다면 모든 럭비 베팅은 80분 경기로 간주합니다.(인저리 시간과 주심에 의해 결정과는 다른 로스타임 포함). <br>
경기장소의 변경 등에 의해(홈팀이 어웨이를 플레이하거나 그 반대의 경우) 경기에 대한 모든 베팅은 무효가 됩니다. <br>
만약 경기가 중립지역에서 개최된다면(아니면 중립지역이 아닌 곳이나 그 반대의 경우) 모든 경기는 지속됩니다. <br>
만약 경기가 80분이 지나가기 전에 취소된다면 모든 경기에 대한 베팅은 무효로 간주됩니다. <br>
만약 전반전이 끝났을 경우 전반전에 대한 모든 베팅은 유효합니다. <br>
진행 중인(라이브)게임에서 보여 지는 스코어는 무시하세요. <br>

5.17 모터 스포츠 <br>
만약 레이스가 걸렬되거나 다음시간으로 연기된다면, 그리고 72시간 안에 재개나 완료가 되지 않는다면, 이 레이스는 유효하지 않은 <br>
것으로 간주되고 모든 베팅은 환불되게 될 것입니다. <br>
공식 테스트 레이스 이후에 출전하지 않은 선수들에 대한 모든 베팅은 환불되지 않을 것 입니다. <br>
이벤트의 결과는 시상식에 따라 결정될 것입니다. <br>
1 대 1 웜 업 lap이 끝난 이후에 적어도 한 명 이상의 레이서가 레이스에 참가하지 않는다면 1 대 1 베팅은 무효로 선언되고 환불되게 될 것입니다. <br>
두 레이서 모두 레이스를 완주하지 못하고 같은 숫자의 lap울 기록했다면 1 대 1 베팅은 무효로 선언되고 환불되게 됩니다. <br>
모든 1 - 대 - 1 베팅은 각 1 - 대 - 1 경기에서 한 명의 레이서가 다른 레이서를 이기는 최종 위치에서 결정되게 됩니다. <br>
예선： 어떤 모터 스포츠의 예선 라운드 베팅은 자격획득 회의 이후에 랭킹 포지션에 근거해서 정해지게 됩니다. <br>
예선 도중 타임 페널티는 공식적 카운트에 의해 지정되게 됩니다. 다른 랭킹 강등이나 승급은 무시되게 됩니다. <br>
가장 빠른 랩: 결정은 레이스 날짜의 가장 빠른 lap 기록을 가진 드라이버에 근거하게 됩니다. <br>
훌수 혹은 짝수: <br>
드라이버가 사고나 자격박탈을 당해서 레이스를 완주하지 못해서 등록되지 못하면 베팅은 무효로 되고 환불되게 됩니다. <br>
5.18 다트 <br>
양 플레이어에 대한 베팅에 대해 게임은 반드시 시작해야 하고 또 반드시 완료되어야 한다. <br>
게임이 끝난 후에 즉시 발표되는 결과로 공식적인 토너먼트에 반영할 것이다. 그 후의 이의에 대해서는 받아들여 지지 않을 것이다. <br>
만약 플레이어가 게임이 시작되기 전에 사퇴하거나 게임 중에 실격할 경우 모든 베팅은 무효 처리한다. <br>
핸디캡 베팅은 세트와 레그 수로 정해진다. <br>
5.19 권투 <br>
만약 선수가 경기에서 라운드 벨을 듣지 못했다면 게임은 그 전 라운드에서 끝난 것으로 간주됩니다. <br>
만약 게임이 연기되거나 12시간 안으로 다시 재시작하지 않는다면, 모든 베팅은 환불 됩니다. <br>
만약 무승부가 세 번째 옵션인 베팅이고, 매치가 무승부로 끝나게 되면 무승부에 대한 베팅은 지불 될 것입니다. <br>
만약 베팅이 권투 선수들에게만 적용되었다면 경기가 무승부로 끝난 경기에서는 모든 베팅이 환불되게 될 것입니다. <br>
베팅에서는 경기 중에 권투 선수의 자격상실은 상대에 의한 KO로 계산 될 것입니다. <br>
Over/Under ：Total Number of Rounds that took place during the match. <br>
게임이 끝난 후 즉시 발표하면서 공식적인 결과에 의해 결정됩니다. <br>
그 후의 항의는 인정되지 않습니다. <br>

5.20 육상경기 <br>
이벤트의 결과는 시상식에 따라 결정됩니다. <br>
베팅의 목적을 위해 자격박탈, 미결정혹온 승급은 인정되지 않습니다. <br>
만약 트랙과 필드 이벤트가 어떠한 이유에라도 이벤트 이전에 취소된다연, 모든 베팅은 무효가 될 것입니다. <br>
5.21 싸이클 <br>
결과는 시상식에 근거합니다. <br>
그 후의 자격상실 미결혹온 승급은 베팅에 영향을 미치지 않습니다. <br>
모든 참가하지 않은 선수에 대한 모든 베팅은 환불되지 않습니다. <br>
IKEA는 어떤 시간에든 사전 공지 없이 어떤 규칙들을 변경할 권리를 가지고 있습니다. <br>
또한 어떤 환경 때문에 어떤 시간에 어떤 베팅들을 취소할 권리를 가지고 있습니다. <br>
이 웹사이트의 컨텐츠들은 영문으로 된 원판에서 번역되었습니다. <br>
만약 어떤 모순이나 불일치가 있다면 영문판이 공식 버전으로 취급될 것입니다. <br>
IKEA는 번역에서 일어난 오류로 인해 발생한 어떠한 손실이나 책임도 지지 않으며, <br>
각 스포츠 베팅사의 '베팅규정'을 먼저 준수합니다.
`,

  tieredBenefits: '등급별 혜택',
  payback: '페이백',
  paybackPercentage: '잃은 금액에 대한 등급별 퍼센트(%)로 페이백!',
  levelUpBenefits: '등급별 레벨업 혜택',
  variousEvents: '등급에 따른 다양한 이벤트! 다양한 혜택!',
  vipTierBenefits: 'VIP등급 별 혜택',
  weeklyPayback: '등급별 주 페이백',
  vipTier: 'VIP 등급',

  termsAndConditions01: '이용 약관',
  application: '신청',
  ageRequirement:
    '모든 신청자는 베팅 또는 IKEA에 등록하기 전에 최소 18세임을 확인해야 합니다. IKEA은 고객에게 나이 증명을 요구할 권리가 있으며, 관련 정보가 제공되기 전에 계정을 중단할 수 있습니다. IKEA은 게임 및 베팅에 대한 적격성을 엄격히 규제합니다.',
  accountInformation:
    '계정 신청 시 제공된 모든 정보는 정확하고 완전해야 합니다. 은행 송금을 사용하는 경우, 계좌 소유자의 이름은 웹사이트에서 계정을 신청할 때 사용한 이름과 일치해야 합니다. 그렇지 않으면 계정이 정지되며 즉시 고객 서비스에 문의해야 합니다. 정지되기 전에 이루어진 모든 베팅은 결과와 관계없이 유효합니다.',
  consentToProvideInformation:
    '귀하는 계정 등록 중 언제든지 관련 법률 및 규정, 규제 기관 및 웹사이트 또는 기타 제품 사용 규칙에 의해 요구되는 신원, 신용 및 기타 검증과 관련된 모든 정보 제공에 동의하는 것으로 간주됩니다. 우리는 관련 검증이 성공적으로 완료될 때까지 귀하의 계정을 중단하거나 제한할 권리를 보유합니다.',
  singleAccount:
    '각 고객은 단 하나의 계정만 사용할 수 있습니다. 고객이 두 개 이상의 계정을 소유하고 있음을 발견할 경우, 우리는 계정을 통합하여 처리할 권리를 보유합니다.',
  updateInformation:
    '귀하는 최신 개인 정보를 제공할 의무가 있으며, 사이트에서 개인 정보 및 계정 정보를 수정할 수 있습니다. 도움이 필요하시면 저희에게 문의하십시오.',
  restrictedRegions: 'IKEA 제한 지역/국가: 미국, 싱가포르, 대만, 마카오, 홍콩 특별행정구, 호주 및 필리핀 공화국.',

  accountInfo: {
    title: '계정 정보',
    section1:
      '귀하는 IKEA에서 등록하고 자신의 아이디와 암호를 선택할 수 있습니다. 고객의 계정은 모든 베팅과 기타 게임에 책임이 있으므로 이러한 정보는 기밀로 유지해야 합니다.',
    section2: '계정에 충분한 자금이 있고 아이디와 비밀 번호가 정확히 입력될 경우 (본인의 승인 여부와 상관없이)베팅은 정상적으로 진행됩니다.',
    section3:
      '언제든지 다른 제3 자가 귀하의 아이디 및 비밀번호를 알고 있다고 생각 되시면 웹 사이트를 통해 즉시 변경해야 합니다. 일부 또는 모든 정보를 잊은 경우 저희에게 연락 하십시오.',
    section4: '참고: 시스템은 고객이 동일한 이름, 동일한 IP, 동일한 컴퓨터 또는 동일한 은행 계좌를 사용 시 동일한 구성원으로 인식합니다.',
    section5: '웹 사이트를 통해 계정에 등록하시면 현재 잔액 및 거래 내역을 볼 수 있습니다.',
  },
  personalInfo01: {
    title: '개인 정보',
    section1: '당사는 귀하가 제공한 개인 정보에 대해 해당 정보 보호법을 준수합니다. 고객의 개인 정보는 당사의 개인 정보 정책에 따라 처리됩니다.',
    section2:
      '고객은 언제든지 개인 정보를 변경할 수 있습니다. 당사는 고객의 부정확한 정보, 업데이트 및 연락에 대한 실패로 인한 출금 또는 기타 손실에 대해 책임을 지지 않습니다. 필요한 경우 즉시 저희에게 연락 하십시오.',
  },

  privacyPolicy: {
    main: '개인정보보호',
    title: '첫째, 정보 보호 및 정책',
    item1:
      'IKEA은 고객의 개인 정보를 보호하기 위해 가장 엄격한 사이버 보안 유지 관리 센터를 만드는데 최선을 다하고 있습니다. 고객의 정보는 당사의 내부에서만 사용하고 제 3자에 판매되거나 양도 하지 않습니다. 우리는 고객의 개인 정보를 보호하기 위해 게임 라이센스의 규칙을 엄격히 준수합니다.',
    item2:
      '고객이 IKEA 웹 사이트에 로그인 하실 때 당사 웹 사이트 사용과 개인정보 보호 정책을 동의합니다. IKEA은 고객의 동의를 거치지 않고 개인 정보 보호 정책을 변경할 수 있는 권리를 보유합니다.',
  },
  personalInformationProtection: {
    sectionTitle: '두번째, 정보 수집 및 사용',
    personalDataCollection:
      '개인 데이터 수집 (예;고객의 이름, 주소, 연락처, 아이디, 은행 계좌 데이터 등 고객을 구분할 수 이는 정보) 고객의 정보는 이메일을 보내거나 질문 등을 제출할 때 수집될 수 있습니다. “개인정보 보호 정책”에 따라 고객께서 개인정보를 제공하지 않으려는 경우 웹 사이트에서 제공하는 물음에 고객의 개인정보를 기입하거나 다른 방법으로 고객에 대한 기타 정보를 당사에 제공하지 마십시오. 아니면 정책의 상관 서비스를 전부 사용할 수 없습니다.',
    usingPersonalInformation: '사용하는 개인 정보',
    purposeUsage: '당사는 고객의 정보를 다음과 같은 목적으로 사용합니다.',
    purposeList: [
      '고객의 계정 정보를 확인하여 고객의 계좌에 관한 질문을',
      '제출된 개인정보를 확인 (예; 이름, 나이 등)',
      'IKEA 프로모션 또는 기타 업데이트 된 서비스에 대한 정보를 제공하거나 전송',
    ],
    additionalUsage:
      '위의 경우를 제외하고 IKEA은 다음과 같은 상황에서 고객의 정보를 사용하고 고객의 정보를 제 3자 파트너 및 당사의 계열사와 공유하여 데이터를 처리합니다.',
    usageSituations: [
      '회원가입, 계정 사용 및 관리',
      '필요한 서비스를 제공하고 적절한 목적을 위해 제공된 물품을 사용',
      '데이터 관련 서비스를 사용하는 사용자 및 기타 고객의 통계',
      '적절한 마케팅 자료를 준비하고 전시',
      '정기적으로 계정 고객에게 중요한 서비스 변경 사항, 기술 업데이트 및 변경 사용 약관 (본 개인정보 보호 정책 포함)을 알림',
      '법률과 법률 절차 준수 또는 스포츠 혹은 기타 자격을 갖춘 그룹의 보호',
      '당사의 서비스를 불법, 사기 및 기타 부정행위에 사용한 혐의에 대한 조사',
      '자금 세탁 또는 기타 사기를 포함한 범죄 혹은 의심되는 범죄행위에 대한 보고',
      '계약상 의무에 대한 당사의 목적을 나타내는 기타 활동',
    ],
    commercialOffers: 'IKEA은 제 3자의 상업적 제안 또는 알려지지 않는 출처의 광고에 대한 정보를 고객에게 보내지 않습니다.',
    customerServiceCalls: '고객서비스의 모든 통화는 교육 및 안전 목적으로 기록됩니다.',
    bankruptcyTransfer: '당사는 회사의 파산을 포함하여 당사의 일부 또는 모든 사업을 판매할 때 고객의 개인정보를 이전할 수 있습니다.',
    stoppingProcessing:
      '고객이 이상 목적으로 인해 고객의 정보 처리를 중단하는 것을 원할 경우, 고객은 당사에 연락하여 해당 활동을 중지하기 위한 적절한 조치를 취해야 합니다. 즉 고객의 계정이 폐쇄 된다는 것을 유의하기 바랍니다.',
  },

  contactCustomerServicefooter: {
    sectionTitle: '세번째, 고객센터로 연락',
    message:
      'IKEA이 개인 정보 보호 정책의 원칙을 준수하지 않는 것을 발견 하신다면, 당사 고객 서비스를 통해 알려주시기 바랍니다. 그리고 당사는 문제를 확인하고 해결하기 위해 모든 합리적인 상업적 조치를 실행합니다.',
  },

  termsAndConditionsPromo: {
    title: '일반 이용약관',
    section1: 'IKEA의 모든 이벤트 및 혜택은 개인, 가족, 집 주소, 이메일 주소, 전화번호, 은행 계좌 및 IP 주소당 1개의 무료 개설 계정으로 제한됩니다.',
    section2: 'IKEA의 모든 이벤트 및 혜택은 정확한 정보(생년월일, 성, 이름, 연락처 번호 등)로 등록한 회원에게만 제공됩니다.',
    section3:
      'IKEA의 모든 이벤트 및 혜택은 고객 또는 고객 그룹이 이벤트의 요건을 위반했을 경우, EX) 1인2계정, 중복IP, 중복계좌 등 고객 이익을 보장하는 쪽으로 변질, 남용할 시에는 모든 보너스와 당첨금을 보류, 취소 또는 회수할 권리를 보유합니다.',
    section4: '모니터링 시 악성유저(양빵, 보너스따먹기)로 판단되면 보너스를 받아 얻은 모든 이익금은 몰수 대상입니다.',
    section5: 'IKEA의 모든 이벤트 및 혜택은 기본적으로 당일 이용 내역을 기준으로 하며 신청일 이 외의 배팅내역으로는 참여가 불가능 합니다.',
    section6: '모든 이용 관련 상시 모니터링 진행중 이며 악의 적인 이용내역 발견시 이벤트 및 혜택 지급 이 불가능 할수 있습니다.',
  },

  responsibleGaming: {
    title: '책임있는 게임',
    message1:
      'IKEA은 모든 고객이 책임감 있게 게임에 참여하기를 바랍니다. 우리는 고객의 책임을 지키고 최고의 온라인 게임 경험을 제공하기 위해 노력합니다.',
    message2:
      '도박은 오락의 한 형태이며, 삶의 부담이 되어서는 안 됩니다. 자신의 능력을 초과하거나 차용과 신용을 통해 이루어지는 도박은 현명하지 않으며 주변 사람들에게 해로울 수 있습니다.<br />IKEA에서의 모든 베팅이 책임감 있고 즐겁기를 바랍니다. 책임 있는 게임 회사로서 우리는 사회적 책임도 가지고 있습니다.',
    message3:
      '이를 위해 우리는 베팅 관리를 돕기 위한 다음과 같은 방법을 제공합니다. 이는 베팅에 소요되는 시간과 금액을 조절하는 데 도움을 줄 것입니다.',
  },

  minorsAccessBlocking: {
    title: '미성년자 접근 차단',
    paragraph1:
      '18세 미만의 개인은 도박이 금지됩니다. IKEA 고객의 연령 확인에 대한 문의가 있습니다. 연령 확인이 고객이 제공한 정보와 일치하지 않는 경우 고객의 계정은 폐쇄되며 잔고는 동결됩니다.<br />법적 조항은 지역마다 다르므로, 고객은 도박 게임에 참여하기 전에 현지 법률을 숙지하시기 바랍니다.',
    paragraph2: '다음은 미성년자가 온라인 게임 사이트에 접근하지 못하도록 막는 몇 가지 리소스입니다.',
    resources: {
      netNanny:
        '넷내니(Net Nanny) (www.netnanny.com): 어린이가 부적절한 웹사이트 콘텐츠에 접근하는 것을 방지하는 범용 차단 소프트웨어입니다. iOS 및 Android 기기에서 모두 적용됩니다.',
      cyberSitter: '사이버시터(CYBERsitter) (www.cybersitter.com): 부모가 맞춤형 필터링 웹사이트를 선택할 수 있도록 하는 필터링 소프트웨어입니다.',
      gamBlock:
        '게임블록(GamBlock) (www.gamblock.com): 사용자가 도박 웹사이트에 접근하지 못하도록 하는 전용 차단 소프트웨어입니다. Android 기기에서도 적용됩니다.',
      betfilter:
        '베트필터(Betfilter) (www.betfilter.com): 사용자가 도박 웹사이트에 접근하지 못하도록 하는 전용 차단 소프트웨어입니다. Android 기기에서도 적용됩니다.',
    },
  },

  gamblingAddictionChecklist: {
    title: '도박 중독 체크리스트',
    introduction: '도박 중독 문제가 있다고 생각되면 다음 질문을 고려해 보십시오.',
    questions: {
      q1: '도박 때문에 근무 시간을 미룬 적이 있습니까?',
      q2: '도박 때문에 삶이 불편하십니까?',
      q3: '도박 때문에 필요한 구매를 자주 미룹니까?',
      q4: '도박 외에 다른 것에 돈을 쓰는 것을 피하고 있습니까?',
      q5: '도박하기 위해 돈을 빌린 적이 있습니까?',
      q6: '도박을 위해 귀중품을 팔아본 적이 있습니까?',
      q7: '도박 때문에 사랑하는 사람을 잃은 적이 있습니까?',
      q8: '도박 때문에 자신과 가족을 소홀히 한 적이 있습니까?',
      q9: '걱정과 어려움을 피하기 위해 도박을 하고 있습니까?',
      q10: '도박 자금을 마련하기 위해 범죄를 저지른 적이 있습니까?',
      q11: '도박 때문에 잠을 잘 못 잔 적이 있습니까?',
      q12: '도박 때문에 자살 충동을 느낀 적이 있습니까?',
    },
    conclusion:
      "대부분의 답변이 '예'라면 심각한 중독 문제가 있을 수 있으며, 가능한 빨리 도움을 요청해야 합니다.<br />고객 서비스 팀에 연락하거나 www.gamcare.org.uk를 방문하여 필요한 도움을 받을 수 있습니다. 또한 기기의 접근을 제한하는 것도 고려하는 것이 좋습니다.",
  },

  accessDeniedToGamblingSites: {
    title: '도박 웹사이트 접근 거부',
    content:
      '도박에 대한 우려가 있는 경우, GamBlock과 같은 차단 소프트웨어를 설치하여 모든 게임 사이트에 대한 접근을 차단하는 것이 좋습니다. 도박 웹사이트를 방문하려고 할 때 경고 메시지가 표시되며 브라우저가 자동으로 닫힙니다. 서비스 요금은 연간 $89.95 (약 €63 또는 £54)이며 자세한 내용은 www.gamblock.com에서 확인할 수 있습니다.',
  },

  controlPathToGamblingAddiction: {
    title: '도박 중독 경로 통제',
    content:
      'IKEA 회원은 다음 방법을 사용하여 도박을 통제할 수 있습니다. 고객은 필요할 때 다음 방법 중 하나를 사용하여 도움을 요청하는 것이 좋습니다.',
  },

  selfRestriction: {
    title: '자기 제한',
    point1:
      '사용하는 예치금의 수와 금액을 줄이거나 제한하십시오.<br />각 예치금의 금액, 주간 또는 월간 예치금을 제한하고 은행 출금 한도를 설정하십시오.',
    point2: '손실 한도, 베팅 한도 및 예치금 한도를 변경하거나 설정해야 합니다.',
    paragraph1:
      '요구 사항을 충족하기 위해 각 거래에 대해 손실 한도, 베팅 한도, 단일 한도 및 예치금 한도를 설정해야 할 수 있습니다. 요청은 수령 후 24시간 이내에 처리됩니다.',
    paragraph2:
      '베팅 한도가 충족되면 관련 설정을 제거할지 여부를 고려하는 7일의 냉각 기간이 제공됩니다. 이는 관련 한도를 제거하기 위한 요청을 수령한 후 7일 이내에는 베팅 한도가 조정되지 않음을 의미합니다.',
    contact: "24시간 온라인 고객 서비스에 문의하거나 이메일 <span class='font-bold'>xxxxxx</span>로 상담할 수 있습니다.",
  },

  selfExclusion: {
    title: '자기 제외',
    definition: '자기 제외란 무엇인가요?',
    description:
      '자기 제외는 자신의 도박 행동에 대해 걱정이 생기기 시작한 회원에게 제공되는 도움으로, 도박에 빠지는 것을 피하는 데 도움이 됩니다. 도박 행동이 우려되는 모든 단계에서 다음 기간 중 하나를 설정하도록 요청할 수 있습니다.',
    duration: '최소 자기 제외 기간 6개월에서 5년.',
    requestProcessTitle: 'IKEA에서 자기 제외 설정을 요청하는 방법은 무엇인가요?',
    requestProcessDescription:
      '24시간 온라인 고객 서비스에 문의하거나 xxxxxx으로 이메일을 보내 자기 제외를 요청할 수 있습니다. 계정 정보와 설정하고 싶은 자기 제외 기간을 제공해 주십시오.<br />특정 기간을 제공하지 않으면 최소 자기 제외 기간인 6개월로 설정하는 데 도움을 드립니다.',
    activationTimeTitle: '자기 제외 신청 후 활성화까지 얼마나 걸리나요?',
    activationTimeDescription:
      '자기 제외 요청을 최대한 빠르게 처리하겠지만, 처리에 합리적인 근무 시간이 필요합니다. 관련 자기 제외 요청은 완전히 확인되고 귀하와의 소통이 완료될 때까지 적용되지 않습니다.',
    reactivationTitle: '자기 제외 중에 내 계정을 재활성화하거나 새 계정을 만들 수 있나요?',
    reactivationDescription: '영구적인 자기 제외가 필요한 경우, IKEA 계정을 다시 생성하는 요청은 더 이상 수락되지 않습니다.',

    permanentSelfExclusionInfo:
      '영구적인 자기 제외 계정을 제외하고, 다른 기간 자기 제외 계정에 대한 요청은 해당 기간이 종료될 때까지 계정을 재생성하거나 자기 제외를 취소할 수 없습니다.<br />IKEA 자기 제외는 우리와 계정 소유자 간의 공동 약속입니다. 우리는 귀하가 재시작하거나 새 계정을 만드는 것을 방지하기 위해 합리적인 조치를 취할 것입니다.<br />마찬가지로 자기 제외 기간 동안 새 계정을 재시작하거나 생성하지 마십시오. 자기 제외 기간 중 새 계정을 만드는 것은 탐지 시 즉시 폐쇄됩니다.',
    accountClosureInfo:
      '자기 제외 이메일을 수령하고 전체 소통이 완료된 후 귀하의 계정이 폐쇄됩니다. 자기 제외 모드에 들어가면 계정에 남아 있는 잔고는 등록된 은행 계좌/마지막으로 선택한 결제 방법으로 환불됩니다.<br />또한, IKEA은 자기 제외 기간 동안 귀하가 프로모션 메시지를 받지 않도록 모든 합리적인 조치를 취할 것입니다.',
    reactivationQuestion: '자기 제외(비영구적 분리) 기간 후에 내 계정을 다시 시작할 수 있습니까?',
    reactivationLimit: '자기 제외 요청은 최대 두 번만 수락됩니다. 세 번째 요청은 영구적인 자기 제외 요청으로 간주되며 계정은 재활성화될 수 없습니다.',
    contactInfo:
      '자기 제외 기간이 끝난 후, 베팅을 계속하고 싶으시다면 고객 서비스 팀에 연락해 주십시오.<br />또한 신중하게 고려해 주시기 바랍니다. 도박 중독 문제가 있다고 판단될 경우 가능한 빨리 도움을 요청해야 합니다.',
  },

  responsibleGaming01: {
    guidelines: {
      1: '도박은 오락 행사로 간주되어야 하며, 개인 예산을 초과해서는 안 됩니다.',
      2: '도박은 돈을 벌기 위한 수단으로 간주되어서는 안 됩니다.',
      3: '베팅으로 인한 손실을 쫓지 마십시오.',
      4: '최소한의 손실로 도박하십시오.',
      5: '도박에 소비한 시간과 돈을 기록하십시오.',
    },
    contactToPause: '사용을 일시 중지해야 하는 경우, 저희에게 연락하여 귀하의 계정을 분리하거나 정지해 주시기 바랍니다.',
    policyCompliance:
      'IKEA은 주요 북메이커의 가이드라인을 준수하며, 플레이어가 책임감 있게 도박할 수 있도록 최선을 다하고 있습니다.<br />우리는 책임 있는 게임 정책 및 서비스를 개발하기 위해 다양한 조직과 밀접하게 협력하는 데 전념하고 있습니다.',
    globalStandards: '글로벌 기준 준수',
    globalStandardsInfo:
      'IKEA에서 전 세계적으로 인식을 높이기 위해 최선을 다하고 있습니다. 따라서 우리는 책임, 정직, 투명성 및 합법성의 모든 측면에서 최고의 서비스를 제공하기 위해 노력하고 있습니다.<br />모두가 가장 높은 기준에 따라 작업할 경우 모든 사람이 이익을 얻을 것이라고 믿으며, 글로벌 기준을 준수하고 높이기 위해 헌신하고 있습니다.',
  },

  tradingCompliance: {
    title: '외환 거래 준수',
    compliance: 'IKEA은 자금 거래에 대한 지역 법률 및 규정을 준수하며, 국제적으로 인정된 결제 거래 절차를 따릅니다.',
    immediatePaymentCommitment: {
      title: '즉시 지급 약속',
      message: 'IKEA은 고객의 현금 인출 요청을 가능한 한 신속하게 처리할 것을 약속합니다.',
    },
    customerServiceCommitment: {
      title: '고객 서비스 약속',
      message:
        'IKEA에 대한 고객 만족은 우리의 최우선 과제이므로, 다양한 방법으로 고객 서비스에 문의할 수 있습니다. 고객 서비스 부서는 연중무휴 24시간, 매일 365일 기술 지원을 제공하고 문제를 해결하며 귀하의 질문에 답변합니다.',
    },
  },

  liveslotsgameTitle: '10. 카지노&슬롯 게임 규정',
  liveslotsgameContent:
    '카지노/슬롯한도<br><br>\n' +
    '카지노 베팅한도 : 500만 원<br>\n' +
    '카지노 당첨한도 : 1,000만 원<br><br>\n' +
    '슬롯 당첨 한도 : 2,000만 원<br><br>\n' +
    '자사에서는 실시간 모든 배팅 모니터링 중이며<br>\n' +
    '슬롯 당첨한도 이상 당첨시에는 차액부분 차감 후 지급 됩니다.<br>\n' +
    '숙지 후 이용 바랍니다.<br><br><br>\n' +
    '\n' +
    '이벤트머니 지급 롤링 안내<br><br>\n' +
    '카지노/슬롯 포인트 미지급시 100%<br>\n' +
    '카지노/슬롯 포인트 첫,매충 지급시 300%<br><br>\n' +
    '추가적인 이벤트로 지급받으신 포인트에대한 롤링금액은 이벤트 주의사항 참고 바랍니다.<br><br><br>\n' +
    '\n' +
    '-타이 [TIE] 결과로 적득된 베팅은 롤링금액에 포함되지 않습니다.<br><br><br>\n' +
    '\n' +
    '\n' +
    '카지노 양방베팅 안내<br><br>\n' +
    '・카지노 양방베팅에 대해서는 운영팀에서 강력하게 제재하고 있음을 알려드리며,<br>\n' +
    '양방베팅이 확인 되실 경우 경고없이 모든금액은 몰수처리되시며, 해당 회원은 이용불가 하오니 주의 부탁드립니다.<br><br><br>\n' +
    '\n' +
    '양방베팅 & 올인성베팅 기준<br><br>\n' +
    '-정상적이지 않은 베팅으로 운영진의 안내를 받고도, 같은 패턴으로 계속 베팅을 진행한 회원<br>\n' +
    '-베팅중 운영진의 모니터링에 의해 상대 베팅에서 양방베팅 & 올인성베팅이 적발된 회원<br>\n' +
    '-게임사에서 악성베팅으로 답변 및 확정된 회원<br><br><br>\n' +
    '\n' +
    '양방베팅 & 올인성베팅 적발시 조치<br><br>\n' +
    '-가입첫충 포함 전체포인트 및 해당 보유머니 전액몰수 및 블랙사이트공유 (탈퇴처리)<br><br><br>\n' +
    '\n' +
    '※ 추가안내사항 (해당내용은 강력조치합니다.)<br><br>\n' +
    '- VPN 사용 시 이유불문하고 추가롤링 혹은 전액몰수/이용제한 조치 되시니 주의하시기 바라며,<br><br>\n' +
    '어떠한 사유에도 VPN 사용을 금하고 있습니다.<br>\n' +
    '위 제재사항은 양방 및 약성유저들에게만 해당됩니다.<br><br><br>\n' +
    '\n' +
    '정상회원님들은 어떠한 불이익도 없을 것이니 안심하시고,<br><br>\n' +
    '최고의 서비스와 함께 즐거운 베팅이 되시도록 최선을 다하는 IKEA 이 되겠습니다.<br>',
  rule_11_title: '11. IKEA 공식 텔레그램 고객센터 관련 안내',
  rule_11_content: `IKEA 공식 텔레그램 고객센터 관련 안내 
<br>
<br>안녕하세요 IKEA 입니다.
<br>IKEA 에서는 회원님들 과 편리한 소통 진행을 위해 텔레그램 고객센터를 운영 하고 있습니다 . 
<br>
<br>텔레그램 {'@'}ikea10 으로 로그인ID 와 문의 내용을 남겨주시면 확인 후 답변 드리도록 하겠습니다 . 
<br>
<br>본사 공식텔레그램은 {'@'}ikea10 만 운영 중에 있으며 다른 모든 계정은 사칭이니 주의 바랍니다 . 
<br>
<br>
<br>최고의 서비스와 함께 즐거운 베팅이 되시도록 최선을 다하는 IKEA 가 되겠습니다.`,
  rule_12_title: '12. 스포츠 ( BTi , BT1 ) 이용 관련 안내',
  rule_12_content: `스포츠 ( BTi , BT1 ) 이용 관련 안내
<br>
<br>안녕하세요. IKEA 입니다.
<br>스포츠 최대 배팅금액 및 당첨금액 관련 규정에 대해 안내드리도록 하겠습니다 . 
<br>
<br>BTi / BT1 규정 동일 합니다. 
<br>
<br>
<br>단 폴더 최대 배팅 가능금액 : 3,000,000 원 
<br>다 폴더 최대 배팅 가능금액 : 3,000,000 원 
<br>
<br>단 폴더 최대 당첨 가능금액 : 10,000,000 원
<br>다 폴더 최대 당첨 가능금액 : 20,000,000 원 
<br>
<br>
<br>자사에서는 실시간 모든 배팅 모니터링 중이며
<br>당첨한도 이상 당첨시에는 차액부분 차감 후 지급 됩니다.
<br>숙지 후 이용 바랍니다 .
<br>* 최대 배팅 가능 금액은 배팅 하시는 경기 및 옵션 마다 상이 할수 있으며 해당 관련 규정은 BTi , BT1 규정을 기반으로 운영됩니다 . 
<br>  ( 비인기 리그 및 하부리그 그리고 배팅 옵션 마다 최대 배팅 가능금액이 다소 하향 될 수 있음으로 확인 후 이용 바랍니다 . )
<br>
<br>
<br>보너스 ( 프로모션 ) 지급 스포츠 롤링 규정 안내 
<br>
<br>보너스 ( 프로모션 ) 지급 시에 단폴더 배팅이 불가 합니다 .
<br>스포츠 단 폴더 , 다 폴더 모두 롤링 100 % 충족 시 출금 신청 가능합니다 . ( 다 폴더 기준 합산 배당이 1.65 이상 이되어야 정상 롤링으로 인정 됩니다.)
<br>실시간 롤링 현황은 MY 페이지 > 롤링 현황에서 확인 가능합니다 . 
<br>
<br>스포츠 이용 관련 내용을 꼭 숙지 하시고 이용 바라며 
<br>해당 관련 규정 미숙지로 인해 불이익이 발생 하지 않으시도록 주의 부탁드립니다 . 
<br>
<br>
<br>최고의 서비스와 함께 즐거운 베팅이 되시도록 최선을 다하는 IKEA 이 되겠습니다.`,
  rule_13_title: '13. 스포츠 크로스 배팅 관련 안내',
  rule_13_content: `스포츠 크로스 배팅 관련 안내
<br>
<br>
<br>안녕하세요. IKEA 입니다 .
<br>저희는 회원님들께 쾌적한 배팅 환경과 다양한 경기 높은 배당율 등을 제공하기 위해
<br>해외 메이저 스포츠 에이전시 BTi , BT1 API를 연동해서 운영하고 있습니다 .
<br>
<br>동일 경기 크로스 배팅 을 이용 하시려는 회원님들께서는 " 비티원 ( BT1 ) " 에서만 배팅가능 합니다. 
<br>단, 동일경기 크로스 배팅 ( 동일경기 승무패 + 언오버 , 동일경기 핸디캡 + 언오버 ) 만 이용 시에는 단폴더로 인정 됩니다 . 
<br>
<br>단폴더 및 다폴더 관련한 규정은 공지사항 " 스포츠 ( BTi , BT1 ) 이용 관련 안내 " 숙지 후 이용 바랍니다 . 
<br>
<br>
<br>
<br>최고의 서비스와 함께 즐거운 베팅이 되시도록 최선을 다하는 IKEA 이 되겠습니다.`,

  transferDetails: '입금계좌 안내',
  to_bank_name: '은행명',
  to_bank_num: '계좌번호',
  to_bank_owner: '예금주',
  telegram_contact_tip: 'IKEA 고객센터 바로가기',
}
