import { createApp } from 'vue'
import './style.css'
import 'animate.css/animate.min.css'
import App from './App.vue'

import en from './locales/en.js'
import ko from './locales/ko.js'
import vn from './locales/vn.js'
import th from './locales/th.js'

import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'
import { createPersistedState } from 'pinia-plugin-persistedstate'
import router from '@/router/index.js'
import { clickOutside } from '@/directive/index.js'
import * as Sentry from '@sentry/vue'

//谷歌统计
const script = document.createElement('script')
script.src = 'https://www.googletagmanager.com/gtag/js?id=G-T6Z1F7FFZB'
script.async = true
document.head.appendChild(script)

window.dataLayer = window.dataLayer || []
function gtag() {
  dataLayer.push(arguments)
}
gtag('js', new Date())
gtag('config', 'G-T6Z1F7FFZB')

const messages = {
  en,
  ko,
  vn,
  th,
}

const i18n = createI18n({
  legacy: false,
  locale: 'ko',
  messages,
})
const pinia = createPinia()
pinia.use(createPersistedState())
const app = createApp(App)

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: 'https://8192520edaeacff151c6afa8c7fa5c82@sentry.link-api.com/4',
    integrations: [
      // 只保留 replay 用于错误回放
      Sentry.replayIntegration(),
    ],
    // 普通会话采样率设为 1%，避免收集过多正常用户数据
    replaysSessionSampleRate: 0.01,
    // 错误会话保持 100% 采样，以便完整收集错误信息
    replaysOnErrorSampleRate: 1.0,
    // 性能追踪采样率设为 0%
    tracesSampleRate: 0,
    beforeSend(event) {
      // 添加空值检查，增加代码健壮性
      if (event?.exception?.values?.[0]) {
        const error = event.exception.values[0]

        // 转换为小写进行比较，避免大小写问题
        const errorType = error.type?.toLowerCase() || ''
        const errorValue = error.value?.toLowerCase() || ''

        if (
          // 网络错误
          errorType.includes('networkerror') ||
          errorValue.match(/net::/i) ||
          errorValue.includes('failed to fetch') ||
          errorValue.includes('network request failed') ||
          // 超时相关
          errorValue.includes('timeout') ||
          errorType.includes('timeouterror') ||
          errorValue.includes('err_connection_timed_out') ||
          // 取消相关
          errorType.includes('cancelederror') ||
          errorType.includes('aborterror') ||
          errorValue.includes('cancel') ||
          errorValue.includes('abort') ||
          // Axios 特定错误
          (errorType.includes('axioserror') && (errorValue.includes('timeout') || errorValue.includes('cancel')))
        ) {
          return null
        }
      }
      return event
    },
    // 添加发送前的判断
    shouldSendCallback(event) {
      // 路由切换导致的请求取消，不发送
      if (event.tags?.['navigation']) {
        return false
      }
      return true
    },
  })
}

app.use(pinia)
app.use(router)
app.use(i18n)

app.directive('click-outside', {
  mounted(el, binding) {
    clickOutside(el, binding)
  },
  beforeUnmount(el) {
    document.removeEventListener('click', el.clickOutsideEvent)
  },
})
app.mount('#app')

// if (window.innerWidth <= 768) {
//   window.location.href = ''
// }

export { i18n }
