<script setup>
import { useRouter } from 'vue-router'
import config from '@/config'

const router = useRouter()
const goView = (url) => {
  router.push(url)
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // 使用平滑滚动
  })
}

const openTelegram = () => {
  window.open(config.telegram)
}
</script>

<template>
  <div class="footer-section" id="footer">
    <footer class="footer-container">
      <div class="container">
        <div class="row indent mb-5">
          <div class="col-auto">
            <h3>{{ $t('quickLinks') }}</h3>
            <ul>
              <li>
                <a @click="goView('/sports-betting-game-rules')">{{ $t('bettingRules') }}</a>
              </li>
              <li>
                <a @click="goView('/privacy-policy')">{{ $t('privacyPolicy.main') }}</a>
              </li>
              <li>
                <a @click="goView('/responsible-gaming')">{{ $t('responsibleGaming.title') }}</a>
              </li>
              <li>
                <a @click="goView('/terms-and-conditions')">{{ $t('termsAndConditions') }}</a>
              </li>
            </ul>
          </div>
          <div class="col-auto">
            <h3>{{ $t('products') }}</h3>
            <div class="row">
              <div class="col-auto">
                <ul>
                  <!--                  <li>-->
                  <!--                    <a @click="goView('/bti-sports')">{{ $t('btiSports') }}</a>-->
                  <!--                  </li>-->
                  <li>
                    <a @click="goView('/bt1-sports')">{{ $t('bt1') }}</a>
                  </li>
                </ul>
              </div>
              <div class="col-auto">
                <ul>
                  <li>
                    <a @click="goView('/promotions')">{{ $t('footerpromotion') }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <h3>{{ $t('help') }}</h3>
            <ul>
              <!--              <li>-->
              <!--                <a @click="goView('/frequently-asked-questions')">{{ $t('faqs') }}</a>-->
              <!--              </li>-->
              <li>
                <a @click="goView('/contact-us')">{{ $t('contactUs') }}</a>
              </li>
            </ul>
          </div>
          <div class="col-auto">
            <h3>{{ $t('supportfooter') }}</h3>
            <div class="row">
              <div class="col-auto click-btn" @click="openTelegram">
                <img class="ImgFluid" src="@/assets/img/icons/telegram2.webp" />
                {{ config.telegram2 }}
              </div>
            </div>
          </div>
          <div class="col-auto">
            <h3 class="pl-3">{{ $t('license') }}</h3>
            <div class="row">
              <div class="col">
                <img class="ImgFluid pl-3 pr-0" src="@/assets/img/provider-logo/responsible-gaming-logo/mga-logo.webp" /><img
                  class="ImgFluid pl-3 pr-0"
                  src="@/assets/img/provider-logo/responsible-gaming-logo/gaming-curacao.webp"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-12">
            <div class="provider-section">
              <h3>{{ $t('providers') }}</h3>
              <div class="row d-flex align-items-center icon-indent">
                <div class="col-auto p-0">
                  <img class="ImgFluid" src="@/assets/img/provider-logo/bti.webp" />
                </div>
                <div class="col-auto" style="padding: 0px 12px">
                  <img class="ImgFluid" src="@/assets/img/provider-logo/evolution.webp" />
                </div>
                <div class="col-auto" style="padding: 0px 12px">
                  <img class="ImgFluid" src="@/assets/img/provider-logo/pragmatic-play.webp" />
                </div>
                <div class="col-auto" style="padding: 0px 12px">
                  <img class="ImgFluid" src="@/assets/img/provider-logo/asia-gaming.webp" />
                </div>
                <div class="col-auto" style="padding: 0px 12px">
                  <img class="ImgFluid" src="@/assets/img/provider-logo/microgaming.webp" />
                </div>
                <div class="col-auto" style="padding: 0px 12px">
                  <img class="ImgFluid" src="@/assets/img/provider-logo/netent.webp" />
                </div>
                <div class="col-auto pr-0">
                  <img class="ImgFluid" src="@/assets/img/provider-logo/red-tiger.webp" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<style scoped>
.click-btn {
  cursor: pointer;
}
</style>
