export default {
  telegram: 'https://t.me/ikea10',
  telegram2: '@ikea10',
  /** 联系方式 */
  contact: '',
  /** 邮箱 */
  email: 'xxxxxx', //'IKEA@gmail.com',
  /** 最小存款金额 */
  min_deposit_amount: 10000,
  /** 最大存款金额 */
  max_deposit_amount: 5000000,
  /** 最小取款金额 */
  min_withdraw_amount: 30000,
  /** 最大取款金额 */
  max_withdraw_amount: 9000000,
}
