<script setup>
import { useRoute, useRouter } from 'vue-router'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import LoginDialog from '@/components/LoginDialog/LoginDialog.vue'
import { getToken } from '@/utils/token.js'
import UserMenu from '@/components/UserMenu/UserMenu.vue'
import { useUserStore } from '@/stores/user.js'
import { useConfigStore } from '@/stores/config.js'
import { useCommonStore } from '@/stores/common.js'

const route = useRoute()
const router = useRouter()
const goView = (path) => {
  if (!isLogin.value && path !== '/register' && path !== '/home') {
    commonStore.showLoginDialog = true
    return
  }
  router.push(path)
}

const configStore = useConfigStore()

const commonStore = useCommonStore()

const isLogin = ref(getToken())
const showUserMenu = ref(false)
const userStore = useUserStore()
const unreadCount = computed(() => userStore.unread_count)
const account = computed(() => userStore.account)
const showLanguageSelect = ref(false)
const instance = getCurrentInstance()
const $t = instance.appContext.config.globalProperties.$t
const config = useConfigStore()
const language = ref(config.language)

const i18n = instance.appContext.config.globalProperties.$i18n
const changeLanguage = (name) => {
  config.language = name
  language.value = name
  i18n.locale = name
}
onMounted(() => {
  if (isLogin.value) {
    userStore.updateInfo()
  }
})
</script>

<template>
  <header id="header" v-if="!configStore.ismainatence">
    <login-dialog v-if="commonStore.showLoginDialog" @close="commonStore.showLoginDialog = false" />
    <div class="container-fluid">
      <div class="row align-items-center justify-content-between">
        <div class="col-auto">
          <div class="website-logo">
            <a @click="router.push('/')"><img class="ImgFluid" src="@/assets/img/logo/IKEALogo.png" alt="Logo" style="width: 150px;height: 30px"/></a>
          </div>
        </div>
        <div class="col-auto">
          <ul class="main_nav">
            <li>
              <a :class="{ active: route.path === '/bti-sports' }" @click="goView('/bti-sports')">
                {{ $t('btiSports') }}
              </a>
            </li>
            <li>
              <a :class="{ active: route.path === '/bt1-sports' }" @click="goView('/bt1-sports')">
                {{ $t('bt1') }}
              </a>
            </li>
            <!-- <li>
              <a :class="{ active: route.path === '/betconstruct' }" @click="goView('/betconstruct')">
                {{ $t('betConstruct') }}
              </a>
            </li> -->
            <li>
              <a :class="{ active: route.path === '/live-casino' }" @click="goView('/live-casino')">
                {{ $t('liveCasino') }}
              </a>
            </li>
            <li>
              <a :class="{ active: route.path === '/slots' }" @click="goView('/slots')">
                {{ $t('slot') }}
              </a>
            </li>
            <!-- <li>
              <a href="">
                {{ $t('liveHoldEm') }}
              </a>
            </li> -->
            <li>
              <a :class="{ active: route.path === '/promotions' }" @click="goView('/promotions')">
                {{ $t('promotions') }}</a>
            </li>
            <li>
              <a :class="{ active: route.path === '/vip' }" @click="goView('/vip')">
                {{ $t('vip') }}
              </a>
            </li>
            <li>
              <a :class="{ active: route.path === '/sports-betting-game-rules' }"
                @click="goView('/sports-betting-game-rules')">
                {{ $t('rules') }}
              </a>
            </li>
            <li>
              <a :class="{ active: route.path === '/myaccount/deposit' }" @click="goView('/myaccount/deposit')">{{
                $t('topdeposit') }}</a>
            </li>
          </ul>
        </div>

        <div v-if="isLogin" class="col-auto login-area">
          <div class="row align-items-center justify-content-end">
            <div class="col-auto">
              <div class="account-details">
                <div class="wallets">
                  <div class="account-name">
                    <div class="notice-badge">
                      <a @click="goView('/myaccount/notices')">
                        <img class="notice-badge-img" src="@/assets/media/Bell-icon.dca9527d.svg" width="22"
                          height="22" />
                        <div v-if="unreadCount > 0" class="badge badge-danger"
                          style="position: relative; top: -10px; left: -20px">
                          {{ unreadCount }}
                        </div>
                      </a>
                    </div>
                    <div class="vip"><img :src="userStore.vip_icon" /></div>
                    <div class="dropdown" v-click-outside="() => {
                      showUserMenu = false
                    }
                      ">
                      <button @click="showUserMenu = !showUserMenu" type="button" id="dropdownMenuLink"
                        aria-haspopup="true" aria-expanded="true" class="dropdown-toggle btn btn-secondary">
                        {{ 'My페이지' }}
                      </button>
                      <user-menu :show="showUserMenu" @close="showUserMenu = false" />
                    </div>
                    <div class="Toastify"></div>
                    <div id="rebateTransferModal" tabindex="-1" role="dialog" aria-labelledby="rebateTransferModal"
                      aria-hidden="true" style="display: none"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-auto ml-1">
              <div class="row">
                <div class="col-auto">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <div class="lang_select">
                        <div class="dropdown" v-click-outside="() => {
                          showLanguageSelect = false
                        }
                          ">
                          <button v-if="language === 'en'" id="dropdownMenuLink" aria-haspopup="true"
                            aria-expanded="false" class="dropdown-toggle btn btn-secondary"
                            @click="showLanguageSelect = true">
                            <img
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAU7SURBVFhH3ZhtUFRVHMafe9d1F5DFBE1D0TREslLDt7W+CCZfaEbJMTNB/VBmGi8S4EtDWCNvqwsipthMqVgzleWHdJocgQ+VSKnoOOO6OOloSYZCsAiyrMvtf87efeGyL5TijD4zd2bvPffuPb99zjn/56xgXrhMGr/PAM34sXBKkiTkl9fio4IfgHu9QFgQoBLlVj+y2THySR2a63Nx0/AJbmwpgipMx5vs7RZEbtuI0dnvYuTsYtxutgBqFW/zKzu9v/0uoFFj6/Y3kLd8Gm5sKsDN0n24Z23HsBmzMIH6L1qOn8DZCZNgmpuE7sYr/FlBELA1PR7S3waU7VgC3LUBrZ2OL31YYu9i7+zpxa6DayA1FSHt7GHUCyG4XrQVwdOfwzTzGUw9exwhM6dBnCndQmxWDiz1tWiImdIHiCl9lR7SX8Uo5UA9gw/kAVDOAP4swIraKg5g2lEM3Zz5mGE2IfbUUWgnT5QfAkThqY2omp+COVInAeXKQLH9gDI4UIkMNAgOKR0ggBQXQAl0cxnApX4AZfvrIIzKhgjtEKSlVkIYu9kDyOkQAem9ARU7hlzXA3DIBWB3Aayo8eJAnReAMbnIzDoMDNMQCJvEI0KAoaIMtAmHPIFO+RlyN+/DoV63A44hVOgeQkbfDuxkAKNzHABBakfficG9FLmAVHivn0OBhpzsEAG1tnfJLb7V2kb3+BpCARzIYADBQ10ATolooV/F82ijpU6iljt3kZZkhBCcDuMLyRwo8u10tNfX4EzMJFyIfgl3Tp52fAuJO3R7Oyo+TJKv+NbubYvIzY+x7Jty1NEK6W8SbzGegKBZj8zVBxyLDesb66Oi38LRWjNr8ik7DZd/aB3XhQVjcUIMerutsFT/xIoN7t1qQWj8y31qkFP+6ghTy4GvIepCIWo10EyMgjbmGX7dU98dv4iOO1Y8QXVMFaiOUfEbFDXvPSidVo+TGiKm8oN9ZtcGS0I9hvt15P9KpQ51ueEUc8Vu65DPHqwE9mvJnx9pBRh4j44eG5DHZo48PqvWQOtIaIgGyYnPylfd6jb/DuuVa7y+9Fo6EL5yKb8eqI54ynr9BjqoNg2JCGcxFjqqTWKQFkdqGmFp6xxQHRmSlFwpf1SIZSG2oaHNz8bCRSjMekVucKj78lVcTU1D26kf6cyG6NS1iNhfisrD57BmyXTHTT6058tfsXb5bPmM9kxRkbBNicbVlemwXP4FKgRhzFvrsJg2TPmHzmFVyh4KqFbHBk/0DiQinDKL5zGcbhaoRauG8bNUSD27+0AwAJP+VTRMnkKBsgaxmTnQU5X/6vVMykJ5WPfBEflO31qffwxCRBZKPz8pX6EAq4/D840/I45yXNjcBDR9Wk75KxgZtJmSOnei4lgWECr3jfVR0W83njNOUzQ3bn+NJ9vM1fPkRhlgnhOgmgCyef76YsFKCOM2Y33KXnJPxAiKMoE0YjjdQ8Fvw/vf8iTrCaSNfpoC4/eY0WiiBJwAU2kJD5RvnjgA6Y8CVFS9wwOnMm2LgQGuyA7EwFLHHGAAXQ4ASshOAGUaDSg2RNgzPoEmykBm6PTxBGTgDnEgSswcyOYGEkGhzKcDHCDW4cCGnP4O0B7mPwMoxZ71C0QOnWRAl2SHGJDCoW47RKnZ4AOAHOBzQHYgoe8Qum8ApQYCxB1iQMwhjyHXVOieI94msbc58MABlBowEA05DmTAb0IExM7T53ExLhHnJ7+IroYLiMrN4wC7Zi2FELnp4QEopQQalY28smr+nxuTEyjuGq1yiQvxL7n4dpFr8sWQAAAAAElFTkSuQmCC"
                              alt="EN" />
                          </button>
                          <button v-else-if="language === 'ko'" id="dropdownMenuLink" aria-haspopup="true"
                            aria-expanded="false" class="dropdown-toggle btn btn-secondary"
                            @click="showLanguageSelect = true">
                            <img
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAhCAYAAACbffiEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAUZSURBVFhH7ZhdTNtVFMBPB4Vuo2Z+BFKy4XR0g82CG5QSCzFkGRAkEIzZEEgcAYQYX8z8WHyVkBAS6oMxoNEH5UsjBB40QDVOkYlAYdAQUhIDCoQEUoRB0bLC8Z7LbSmDftD+HxT9NTf33nNPz7mn997//57KkAFHgGOi/tfz3w0kJSUFbDab6EnPxsYG6HQ60TsEdEb8paCggM4TTk9PY319vZBKh8Fg4LbJB/k6DH4HMjg4yB2MjIygWq3m7ampKbRarUIjcJaXl9FisXCbZHt0dJS3jUaj0PCN34GQ4czMTOzo6OBth8PB69jYWKEROM4fxmmzvb0d09LSsKamRmj4xq9AcnJyuAOCatoC5eXlLpkUkK2ysjKsq6tz2aXtlZ6eztu+8DmTvr4+bnhiYgJjYmJQqVTi/Pw8l3V1dQmt/Sy196Dl5m0cSyvEMX0hWl55B5e+6haj++ns7HQFoFAoMDo6GoeGhriM5uALn4GQoby8PGxpaXE5opo9vXj7YRY++gJ/gBi8A2ewD9T4U0g8L9QmGY0tfPyl0N7LzMwMX332VOQ+yGd+fr7Lrzd8ahQXF+Ps7Cw31tDQgCUlJR4Nm6/dxO9Bhf3HE/BuxLMHFhojHfO1UvGtvZBt8kG+qE2+i4qKxKhnfL5HmpqaYHFxERISEkCr1fJ+d3e3GN3FUvImWI3fQXhEFMhCPJulMdKxGr8FS/EtId2lp6eH+yBfGo2G+25ubhajnjnUXauqqgqGh4d5ccc6PAFm7fMQdvI0yGQyIfUOubXb5kFrugMRVy4J6Q7JycmQlJQEjY2NQuIbSS6Nn2hLQHPPBFsKhZAcAHMjZyUEEBwsWAfIYNu+CSeeOQ+XRzuFUuAEfdfaYOXu8BSEyUN3BAcQitsQ5fgLfg2PgN5HVPB72Enel4fLYeVev9AKjqAD6R/9DSYfVYECt4RkL2HbW7B2TA4XL+VB4YVMuP1kKrwUlw1J8S/wlQlnn7XBcaEdOEEHYrOuws+Ralg/FgqKbQffQk4oCAVbjavxOWAPkQOwVYCtTYAHf8KK/DjoWTBKtsm2rH+IbwRO0IFEPhbBJrYJWjapBTY5FZvkKYcdolh9n00+mQUBtFosoD2QjAX/qfwcKE8phTBwJDnsMtWrAKefYIchHFQbyxBrX4MZdh5mTzzOVsHONDy4kIXAc2O/QL/dKASBE/SKEGrtOYDNB3zrLLCD3McO9CwLhG8lT0EQ7Kl15moAuccBHCoQeo/QM/5hmt67DrB0n82ZJs0KbSNfC03jS6vwbm25EOxCPiorK0XPP/wKhOUgkJiYyI2bTCb+9nUnJfEpKLieCrBCD2M/WbHBizdSIUFzVgh26O3t5T7oR6PbBPn2B5+BsHsPREZGwvj4OLDkivezs7PF6C4d75eCXn8eYHEVYNvLatAY09HrL0C7oVQId8nKyuI+yJfZbOa+2X1PjHqBDrs3SMX99kvZHNWebr8ftrBr/9nXEJ5+HeHiGwiaWzuF2iRjYx80/yi09+K8/c7NzXEf5JN8+zFN//MR9utgXFwcsmXHyclJLvOWj7R+bcIbb32GlwsNeOVlAxa+/Tm2fWMSo/txz0cGBgZ41kg1ySTJRwj3DJFdCrG2thYrKipcMikgW5R1VldXu+zm5uZKlyE6IeMZGRnY1tbG21Lm7GTD3WZrayvqdDrpc3biyPyLQhyJ/7WcsMwN19fXRU96KF/39ET0xv//xv/TOCKBAPwNhuBpSQOkaKUAAAAASUVORK5CYII="
                              alt="KR" />
                          </button>
                          <button v-else-if="language === 'vn'" id="dropdownMenuLink" aria-haspopup="true"
                            aria-expanded="false" class="dropdown-toggle btn btn-secondary"
                            @click="showLanguageSelect = true">
                            <img src="@/assets/img/icons/vn.webp" alt="VN" />
                          </button>
                          <button v-else-if="language === 'th'" id="dropdownMenuLink" aria-haspopup="true"
                            aria-expanded="false" class="dropdown-toggle btn btn-secondary"
                            @click="showLanguageSelect = true">
                            <img src="@/assets/img/icons/th.webp" alt="TH" />
                          </button>

                          <!-- 容错 -->
                          <button v-else id="dropdownMenuLink" aria-haspopup="true" aria-expanded="false"
                            class="dropdown-toggle btn btn-secondary" @click="showLanguageSelect = true">
                            <img
                              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAhCAYAAACbffiEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAUZSURBVFhH7ZhdTNtVFMBPB4Vuo2Z+BFKy4XR0g82CG5QSCzFkGRAkEIzZEEgcAYQYX8z8WHyVkBAS6oMxoNEH5UsjBB40QDVOkYlAYdAQUhIDCoQEUoRB0bLC8Z7LbSmDftD+HxT9NTf33nNPz7mn997//57KkAFHgGOi/tfz3w0kJSUFbDab6EnPxsYG6HQ60TsEdEb8paCggM4TTk9PY319vZBKh8Fg4LbJB/k6DH4HMjg4yB2MjIygWq3m7ampKbRarUIjcJaXl9FisXCbZHt0dJS3jUaj0PCN34GQ4czMTOzo6OBth8PB69jYWKEROM4fxmmzvb0d09LSsKamRmj4xq9AcnJyuAOCatoC5eXlLpkUkK2ysjKsq6tz2aXtlZ6eztu+8DmTvr4+bnhiYgJjYmJQqVTi/Pw8l3V1dQmt/Sy196Dl5m0cSyvEMX0hWl55B5e+6haj++ns7HQFoFAoMDo6GoeGhriM5uALn4GQoby8PGxpaXE5opo9vXj7YRY++gJ/gBi8A2ewD9T4U0g8L9QmGY0tfPyl0N7LzMwMX332VOQ+yGd+fr7Lrzd8ahQXF+Ps7Cw31tDQgCUlJR4Nm6/dxO9Bhf3HE/BuxLMHFhojHfO1UvGtvZBt8kG+qE2+i4qKxKhnfL5HmpqaYHFxERISEkCr1fJ+d3e3GN3FUvImWI3fQXhEFMhCPJulMdKxGr8FS/EtId2lp6eH+yBfGo2G+25ubhajnjnUXauqqgqGh4d5ccc6PAFm7fMQdvI0yGQyIfUOubXb5kFrugMRVy4J6Q7JycmQlJQEjY2NQuIbSS6Nn2hLQHPPBFsKhZAcAHMjZyUEEBwsWAfIYNu+CSeeOQ+XRzuFUuAEfdfaYOXu8BSEyUN3BAcQitsQ5fgLfg2PgN5HVPB72Enel4fLYeVev9AKjqAD6R/9DSYfVYECt4RkL2HbW7B2TA4XL+VB4YVMuP1kKrwUlw1J8S/wlQlnn7XBcaEdOEEHYrOuws+Ralg/FgqKbQffQk4oCAVbjavxOWAPkQOwVYCtTYAHf8KK/DjoWTBKtsm2rH+IbwRO0IFEPhbBJrYJWjapBTY5FZvkKYcdolh9n00+mQUBtFosoD2QjAX/qfwcKE8phTBwJDnsMtWrAKefYIchHFQbyxBrX4MZdh5mTzzOVsHONDy4kIXAc2O/QL/dKASBE/SKEGrtOYDNB3zrLLCD3McO9CwLhG8lT0EQ7Kl15moAuccBHCoQeo/QM/5hmt67DrB0n82ZJs0KbSNfC03jS6vwbm25EOxCPiorK0XPP/wKhOUgkJiYyI2bTCb+9nUnJfEpKLieCrBCD2M/WbHBizdSIUFzVgh26O3t5T7oR6PbBPn2B5+BsHsPREZGwvj4OLDkivezs7PF6C4d75eCXn8eYHEVYNvLatAY09HrL0C7oVQId8nKyuI+yJfZbOa+2X1PjHqBDrs3SMX99kvZHNWebr8ftrBr/9nXEJ5+HeHiGwiaWzuF2iRjYx80/yi09+K8/c7NzXEf5JN8+zFN//MR9utgXFwcsmXHyclJLvOWj7R+bcIbb32GlwsNeOVlAxa+/Tm2fWMSo/txz0cGBgZ41kg1ySTJRwj3DJFdCrG2thYrKipcMikgW5R1VldXu+zm5uZKlyE6IeMZGRnY1tbG21Lm7GTD3WZrayvqdDrpc3biyPyLQhyJ/7WcsMwN19fXRU96KF/39ET0xv//xv/TOCKBAPwNhuBpSQOkaKUAAAAASUVORK5CYII="
                              alt="KR" />
                          </button>
                          <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu"
                            :class="{ show: showLanguageSelect }">
                            <button @click="changeLanguage('en')" v-if="language !== 'en'" tabindex="0" role="menuitem"
                              class="dropdown-item">
                              <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAU7SURBVFhH3ZhtUFRVHMafe9d1F5DFBE1D0TREslLDt7W+CCZfaEbJMTNB/VBmGi8S4EtDWCNvqwsipthMqVgzleWHdJocgQ+VSKnoOOO6OOloSYZCsAiyrMvtf87efeGyL5TijD4zd2bvPffuPb99zjn/56xgXrhMGr/PAM34sXBKkiTkl9fio4IfgHu9QFgQoBLlVj+y2THySR2a63Nx0/AJbmwpgipMx5vs7RZEbtuI0dnvYuTsYtxutgBqFW/zKzu9v/0uoFFj6/Y3kLd8Gm5sKsDN0n24Z23HsBmzMIH6L1qOn8DZCZNgmpuE7sYr/FlBELA1PR7S3waU7VgC3LUBrZ2OL31YYu9i7+zpxa6DayA1FSHt7GHUCyG4XrQVwdOfwzTzGUw9exwhM6dBnCndQmxWDiz1tWiImdIHiCl9lR7SX8Uo5UA9gw/kAVDOAP4swIraKg5g2lEM3Zz5mGE2IfbUUWgnT5QfAkThqY2omp+COVInAeXKQLH9gDI4UIkMNAgOKR0ggBQXQAl0cxnApX4AZfvrIIzKhgjtEKSlVkIYu9kDyOkQAem9ARU7hlzXA3DIBWB3Aayo8eJAnReAMbnIzDoMDNMQCJvEI0KAoaIMtAmHPIFO+RlyN+/DoV63A44hVOgeQkbfDuxkAKNzHABBakfficG9FLmAVHivn0OBhpzsEAG1tnfJLb7V2kb3+BpCARzIYADBQ10ATolooV/F82ijpU6iljt3kZZkhBCcDuMLyRwo8u10tNfX4EzMJFyIfgl3Tp52fAuJO3R7Oyo+TJKv+NbubYvIzY+x7Jty1NEK6W8SbzGegKBZj8zVBxyLDesb66Oi38LRWjNr8ik7DZd/aB3XhQVjcUIMerutsFT/xIoN7t1qQWj8y31qkFP+6ghTy4GvIepCIWo10EyMgjbmGX7dU98dv4iOO1Y8QXVMFaiOUfEbFDXvPSidVo+TGiKm8oN9ZtcGS0I9hvt15P9KpQ51ueEUc8Vu65DPHqwE9mvJnx9pBRh4j44eG5DHZo48PqvWQOtIaIgGyYnPylfd6jb/DuuVa7y+9Fo6EL5yKb8eqI54ynr9BjqoNg2JCGcxFjqqTWKQFkdqGmFp6xxQHRmSlFwpf1SIZSG2oaHNz8bCRSjMekVucKj78lVcTU1D26kf6cyG6NS1iNhfisrD57BmyXTHTT6058tfsXb5bPmM9kxRkbBNicbVlemwXP4FKgRhzFvrsJg2TPmHzmFVyh4KqFbHBk/0DiQinDKL5zGcbhaoRauG8bNUSD27+0AwAJP+VTRMnkKBsgaxmTnQU5X/6vVMykJ5WPfBEflO31qffwxCRBZKPz8pX6EAq4/D840/I45yXNjcBDR9Wk75KxgZtJmSOnei4lgWECr3jfVR0W83njNOUzQ3bn+NJ9vM1fPkRhlgnhOgmgCyef76YsFKCOM2Y33KXnJPxAiKMoE0YjjdQ8Fvw/vf8iTrCaSNfpoC4/eY0WiiBJwAU2kJD5RvnjgA6Y8CVFS9wwOnMm2LgQGuyA7EwFLHHGAAXQ4ASshOAGUaDSg2RNgzPoEmykBm6PTxBGTgDnEgSswcyOYGEkGhzKcDHCDW4cCGnP4O0B7mPwMoxZ71C0QOnWRAl2SHGJDCoW47RKnZ4AOAHOBzQHYgoe8Qum8ApQYCxB1iQMwhjyHXVOieI94msbc58MABlBowEA05DmTAb0IExM7T53ExLhHnJ7+IroYLiMrN4wC7Zi2FELnp4QEopQQalY28smr+nxuTEyjuGq1yiQvxL7n4dpFr8sWQAAAAAElFTkSuQmCC"
                                alt="EN" />
                              English
                            </button>
                            <button @click="changeLanguage('ko')" v-if="language !== 'ko'" tabindex="0" role="menuitem"
                              class="dropdown-item">
                              <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAhCAYAAACbffiEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAUZSURBVFhH7ZhdTNtVFMBPB4Vuo2Z+BFKy4XR0g82CG5QSCzFkGRAkEIzZEEgcAYQYX8z8WHyVkBAS6oMxoNEH5UsjBB40QDVOkYlAYdAQUhIDCoQEUoRB0bLC8Z7LbSmDftD+HxT9NTf33nNPz7mn997//57KkAFHgGOi/tfz3w0kJSUFbDab6EnPxsYG6HQ60TsEdEb8paCggM4TTk9PY319vZBKh8Fg4LbJB/k6DH4HMjg4yB2MjIygWq3m7ampKbRarUIjcJaXl9FisXCbZHt0dJS3jUaj0PCN34GQ4czMTOzo6OBth8PB69jYWKEROM4fxmmzvb0d09LSsKamRmj4xq9AcnJyuAOCatoC5eXlLpkUkK2ysjKsq6tz2aXtlZ6eztu+8DmTvr4+bnhiYgJjYmJQqVTi/Pw8l3V1dQmt/Sy196Dl5m0cSyvEMX0hWl55B5e+6haj++ns7HQFoFAoMDo6GoeGhriM5uALn4GQoby8PGxpaXE5opo9vXj7YRY++gJ/gBi8A2ewD9T4U0g8L9QmGY0tfPyl0N7LzMwMX332VOQ+yGd+fr7Lrzd8ahQXF+Ps7Cw31tDQgCUlJR4Nm6/dxO9Bhf3HE/BuxLMHFhojHfO1UvGtvZBt8kG+qE2+i4qKxKhnfL5HmpqaYHFxERISEkCr1fJ+d3e3GN3FUvImWI3fQXhEFMhCPJulMdKxGr8FS/EtId2lp6eH+yBfGo2G+25ubhajnjnUXauqqgqGh4d5ccc6PAFm7fMQdvI0yGQyIfUOubXb5kFrugMRVy4J6Q7JycmQlJQEjY2NQuIbSS6Nn2hLQHPPBFsKhZAcAHMjZyUEEBwsWAfIYNu+CSeeOQ+XRzuFUuAEfdfaYOXu8BSEyUN3BAcQitsQ5fgLfg2PgN5HVPB72Enel4fLYeVev9AKjqAD6R/9DSYfVYECt4RkL2HbW7B2TA4XL+VB4YVMuP1kKrwUlw1J8S/wlQlnn7XBcaEdOEEHYrOuws+Ralg/FgqKbQffQk4oCAVbjavxOWAPkQOwVYCtTYAHf8KK/DjoWTBKtsm2rH+IbwRO0IFEPhbBJrYJWjapBTY5FZvkKYcdolh9n00+mQUBtFosoD2QjAX/qfwcKE8phTBwJDnsMtWrAKefYIchHFQbyxBrX4MZdh5mTzzOVsHONDy4kIXAc2O/QL/dKASBE/SKEGrtOYDNB3zrLLCD3McO9CwLhG8lT0EQ7Kl15moAuccBHCoQeo/QM/5hmt67DrB0n82ZJs0KbSNfC03jS6vwbm25EOxCPiorK0XPP/wKhOUgkJiYyI2bTCb+9nUnJfEpKLieCrBCD2M/WbHBizdSIUFzVgh26O3t5T7oR6PbBPn2B5+BsHsPREZGwvj4OLDkivezs7PF6C4d75eCXn8eYHEVYNvLatAY09HrL0C7oVQId8nKyuI+yJfZbOa+2X1PjHqBDrs3SMX99kvZHNWebr8ftrBr/9nXEJ5+HeHiGwiaWzuF2iRjYx80/yi09+K8/c7NzXEf5JN8+zFN//MR9utgXFwcsmXHyclJLvOWj7R+bcIbb32GlwsNeOVlAxa+/Tm2fWMSo/txz0cGBgZ41kg1ySTJRwj3DJFdCrG2thYrKipcMikgW5R1VldXu+zm5uZKlyE6IeMZGRnY1tbG21Lm7GTD3WZrayvqdDrpc3biyPyLQhyJ/7WcsMwN19fXRU96KF/39ET0xv//xv/TOCKBAPwNhuBpSQOkaKUAAAAASUVORK5CYII="
                                alt="KR" />
                              한국어
                            </button>
                            <button @click="changeLanguage('vn')" v-if="language !== 'vn'" tabindex="0" role="menuitem"
                              class="dropdown-item">
                              <img src="@/assets/img/icons/vn.webp" alt="VN" />
                              việt nam
                            </button>
                            <button @click="changeLanguage('th')" v-if="language !== 'th'" tabindex="0" role="menuitem"
                              class="dropdown-item">
                              <img src="@/assets/img/icons/th.webp" alt="TH" />
                              ประเทศไทย
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="col-auto login-area">
          <div class="col-auto px-1">
            <button @click="commonStore.showLoginDialog = true" type="button" class="btn btn-success btn-sm btn-login"
              id="login-btn">
              {{ $t('login') }}
            </button>
          </div>
          <div class="col-auto px-1">
            <a class="btn btn-secondary btn-sm btn-register" id="createAccount" name="Create an Account"
              @click="goView('/register')">{{
                $t('register')
              }}</a>
          </div>
          <div class="col-auto lang_select px-2">
            <div class="dropdown" v-click-outside="() => {
              showLanguageSelect = false
            }
              ">
              <button v-if="language === 'en'" id="dropdownMenuLink" aria-haspopup="true" aria-expanded="false"
                class="dropdown-toggle btn btn-secondary" @click="showLanguageSelect = true">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAU7SURBVFhH3ZhtUFRVHMafe9d1F5DFBE1D0TREslLDt7W+CCZfaEbJMTNB/VBmGi8S4EtDWCNvqwsipthMqVgzleWHdJocgQ+VSKnoOOO6OOloSYZCsAiyrMvtf87efeGyL5TijD4zd2bvPffuPb99zjn/56xgXrhMGr/PAM34sXBKkiTkl9fio4IfgHu9QFgQoBLlVj+y2THySR2a63Nx0/AJbmwpgipMx5vs7RZEbtuI0dnvYuTsYtxutgBqFW/zKzu9v/0uoFFj6/Y3kLd8Gm5sKsDN0n24Z23HsBmzMIH6L1qOn8DZCZNgmpuE7sYr/FlBELA1PR7S3waU7VgC3LUBrZ2OL31YYu9i7+zpxa6DayA1FSHt7GHUCyG4XrQVwdOfwzTzGUw9exwhM6dBnCndQmxWDiz1tWiImdIHiCl9lR7SX8Uo5UA9gw/kAVDOAP4swIraKg5g2lEM3Zz5mGE2IfbUUWgnT5QfAkThqY2omp+COVInAeXKQLH9gDI4UIkMNAgOKR0ggBQXQAl0cxnApX4AZfvrIIzKhgjtEKSlVkIYu9kDyOkQAem9ARU7hlzXA3DIBWB3Aayo8eJAnReAMbnIzDoMDNMQCJvEI0KAoaIMtAmHPIFO+RlyN+/DoV63A44hVOgeQkbfDuxkAKNzHABBakfficG9FLmAVHivn0OBhpzsEAG1tnfJLb7V2kb3+BpCARzIYADBQ10ATolooV/F82ijpU6iljt3kZZkhBCcDuMLyRwo8u10tNfX4EzMJFyIfgl3Tp52fAuJO3R7Oyo+TJKv+NbubYvIzY+x7Jty1NEK6W8SbzGegKBZj8zVBxyLDesb66Oi38LRWjNr8ik7DZd/aB3XhQVjcUIMerutsFT/xIoN7t1qQWj8y31qkFP+6ghTy4GvIepCIWo10EyMgjbmGX7dU98dv4iOO1Y8QXVMFaiOUfEbFDXvPSidVo+TGiKm8oN9ZtcGS0I9hvt15P9KpQ51ueEUc8Vu65DPHqwE9mvJnx9pBRh4j44eG5DHZo48PqvWQOtIaIgGyYnPylfd6jb/DuuVa7y+9Fo6EL5yKb8eqI54ynr9BjqoNg2JCGcxFjqqTWKQFkdqGmFp6xxQHRmSlFwpf1SIZSG2oaHNz8bCRSjMekVucKj78lVcTU1D26kf6cyG6NS1iNhfisrD57BmyXTHTT6058tfsXb5bPmM9kxRkbBNicbVlemwXP4FKgRhzFvrsJg2TPmHzmFVyh4KqFbHBk/0DiQinDKL5zGcbhaoRauG8bNUSD27+0AwAJP+VTRMnkKBsgaxmTnQU5X/6vVMykJ5WPfBEflO31qffwxCRBZKPz8pX6EAq4/D840/I45yXNjcBDR9Wk75KxgZtJmSOnei4lgWECr3jfVR0W83njNOUzQ3bn+NJ9vM1fPkRhlgnhOgmgCyef76YsFKCOM2Y33KXnJPxAiKMoE0YjjdQ8Fvw/vf8iTrCaSNfpoC4/eY0WiiBJwAU2kJD5RvnjgA6Y8CVFS9wwOnMm2LgQGuyA7EwFLHHGAAXQ4ASshOAGUaDSg2RNgzPoEmykBm6PTxBGTgDnEgSswcyOYGEkGhzKcDHCDW4cCGnP4O0B7mPwMoxZ71C0QOnWRAl2SHGJDCoW47RKnZ4AOAHOBzQHYgoe8Qum8ApQYCxB1iQMwhjyHXVOieI94msbc58MABlBowEA05DmTAb0IExM7T53ExLhHnJ7+IroYLiMrN4wC7Zi2FELnp4QEopQQalY28smr+nxuTEyjuGq1yiQvxL7n4dpFr8sWQAAAAAElFTkSuQmCC"
                  alt="EN" />
              </button>
              <button v-if="language === 'ko'" id="dropdownMenuLink" aria-haspopup="true" aria-expanded="false"
                class="dropdown-toggle btn btn-secondary" @click="showLanguageSelect = true">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAhCAYAAACbffiEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAUZSURBVFhH7ZhdTNtVFMBPB4Vuo2Z+BFKy4XR0g82CG5QSCzFkGRAkEIzZEEgcAYQYX8z8WHyVkBAS6oMxoNEH5UsjBB40QDVOkYlAYdAQUhIDCoQEUoRB0bLC8Z7LbSmDftD+HxT9NTf33nNPz7mn997//57KkAFHgGOi/tfz3w0kJSUFbDab6EnPxsYG6HQ60TsEdEb8paCggM4TTk9PY319vZBKh8Fg4LbJB/k6DH4HMjg4yB2MjIygWq3m7ampKbRarUIjcJaXl9FisXCbZHt0dJS3jUaj0PCN34GQ4czMTOzo6OBth8PB69jYWKEROM4fxmmzvb0d09LSsKamRmj4xq9AcnJyuAOCatoC5eXlLpkUkK2ysjKsq6tz2aXtlZ6eztu+8DmTvr4+bnhiYgJjYmJQqVTi/Pw8l3V1dQmt/Sy196Dl5m0cSyvEMX0hWl55B5e+6haj++ns7HQFoFAoMDo6GoeGhriM5uALn4GQoby8PGxpaXE5opo9vXj7YRY++gJ/gBi8A2ewD9T4U0g8L9QmGY0tfPyl0N7LzMwMX332VOQ+yGd+fr7Lrzd8ahQXF+Ps7Cw31tDQgCUlJR4Nm6/dxO9Bhf3HE/BuxLMHFhojHfO1UvGtvZBt8kG+qE2+i4qKxKhnfL5HmpqaYHFxERISEkCr1fJ+d3e3GN3FUvImWI3fQXhEFMhCPJulMdKxGr8FS/EtId2lp6eH+yBfGo2G+25ubhajnjnUXauqqgqGh4d5ccc6PAFm7fMQdvI0yGQyIfUOubXb5kFrugMRVy4J6Q7JycmQlJQEjY2NQuIbSS6Nn2hLQHPPBFsKhZAcAHMjZyUEEBwsWAfIYNu+CSeeOQ+XRzuFUuAEfdfaYOXu8BSEyUN3BAcQitsQ5fgLfg2PgN5HVPB72Enel4fLYeVev9AKjqAD6R/9DSYfVYECt4RkL2HbW7B2TA4XL+VB4YVMuP1kKrwUlw1J8S/wlQlnn7XBcaEdOEEHYrOuws+Ralg/FgqKbQffQk4oCAVbjavxOWAPkQOwVYCtTYAHf8KK/DjoWTBKtsm2rH+IbwRO0IFEPhbBJrYJWjapBTY5FZvkKYcdolh9n00+mQUBtFosoD2QjAX/qfwcKE8phTBwJDnsMtWrAKefYIchHFQbyxBrX4MZdh5mTzzOVsHONDy4kIXAc2O/QL/dKASBE/SKEGrtOYDNB3zrLLCD3McO9CwLhG8lT0EQ7Kl15moAuccBHCoQeo/QM/5hmt67DrB0n82ZJs0KbSNfC03jS6vwbm25EOxCPiorK0XPP/wKhOUgkJiYyI2bTCb+9nUnJfEpKLieCrBCD2M/WbHBizdSIUFzVgh26O3t5T7oR6PbBPn2B5+BsHsPREZGwvj4OLDkivezs7PF6C4d75eCXn8eYHEVYNvLatAY09HrL0C7oVQId8nKyuI+yJfZbOa+2X1PjHqBDrs3SMX99kvZHNWebr8ftrBr/9nXEJ5+HeHiGwiaWzuF2iRjYx80/yi09+K8/c7NzXEf5JN8+zFN//MR9utgXFwcsmXHyclJLvOWj7R+bcIbb32GlwsNeOVlAxa+/Tm2fWMSo/txz0cGBgZ41kg1ySTJRwj3DJFdCrG2thYrKipcMikgW5R1VldXu+zm5uZKlyE6IeMZGRnY1tbG21Lm7GTD3WZrayvqdDrpc3biyPyLQhyJ/7WcsMwN19fXRU96KF/39ET0xv//xv/TOCKBAPwNhuBpSQOkaKUAAAAASUVORK5CYII="
                  alt="KR" />
              </button>
              <!-- 容错 -->
              <button v-else id="dropdownMenuLink" aria-haspopup="true" aria-expanded="false"
                class="dropdown-toggle btn btn-secondary" @click="showLanguageSelect = true">
                <img
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAhCAYAAACbffiEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAUZSURBVFhH7ZhdTNtVFMBPB4Vuo2Z+BFKy4XR0g82CG5QSCzFkGRAkEIzZEEgcAYQYX8z8WHyVkBAS6oMxoNEH5UsjBB40QDVOkYlAYdAQUhIDCoQEUoRB0bLC8Z7LbSmDftD+HxT9NTf33nNPz7mn997//57KkAFHgGOi/tfz3w0kJSUFbDab6EnPxsYG6HQ60TsEdEb8paCggM4TTk9PY319vZBKh8Fg4LbJB/k6DH4HMjg4yB2MjIygWq3m7ampKbRarUIjcJaXl9FisXCbZHt0dJS3jUaj0PCN34GQ4czMTOzo6OBth8PB69jYWKEROM4fxmmzvb0d09LSsKamRmj4xq9AcnJyuAOCatoC5eXlLpkUkK2ysjKsq6tz2aXtlZ6eztu+8DmTvr4+bnhiYgJjYmJQqVTi/Pw8l3V1dQmt/Sy196Dl5m0cSyvEMX0hWl55B5e+6haj++ns7HQFoFAoMDo6GoeGhriM5uALn4GQoby8PGxpaXE5opo9vXj7YRY++gJ/gBi8A2ewD9T4U0g8L9QmGY0tfPyl0N7LzMwMX332VOQ+yGd+fr7Lrzd8ahQXF+Ps7Cw31tDQgCUlJR4Nm6/dxO9Bhf3HE/BuxLMHFhojHfO1UvGtvZBt8kG+qE2+i4qKxKhnfL5HmpqaYHFxERISEkCr1fJ+d3e3GN3FUvImWI3fQXhEFMhCPJulMdKxGr8FS/EtId2lp6eH+yBfGo2G+25ubhajnjnUXauqqgqGh4d5ccc6PAFm7fMQdvI0yGQyIfUOubXb5kFrugMRVy4J6Q7JycmQlJQEjY2NQuIbSS6Nn2hLQHPPBFsKhZAcAHMjZyUEEBwsWAfIYNu+CSeeOQ+XRzuFUuAEfdfaYOXu8BSEyUN3BAcQitsQ5fgLfg2PgN5HVPB72Enel4fLYeVev9AKjqAD6R/9DSYfVYECt4RkL2HbW7B2TA4XL+VB4YVMuP1kKrwUlw1J8S/wlQlnn7XBcaEdOEEHYrOuws+Ralg/FgqKbQffQk4oCAVbjavxOWAPkQOwVYCtTYAHf8KK/DjoWTBKtsm2rH+IbwRO0IFEPhbBJrYJWjapBTY5FZvkKYcdolh9n00+mQUBtFosoD2QjAX/qfwcKE8phTBwJDnsMtWrAKefYIchHFQbyxBrX4MZdh5mTzzOVsHONDy4kIXAc2O/QL/dKASBE/SKEGrtOYDNB3zrLLCD3McO9CwLhG8lT0EQ7Kl15moAuccBHCoQeo/QM/5hmt67DrB0n82ZJs0KbSNfC03jS6vwbm25EOxCPiorK0XPP/wKhOUgkJiYyI2bTCb+9nUnJfEpKLieCrBCD2M/WbHBizdSIUFzVgh26O3t5T7oR6PbBPn2B5+BsHsPREZGwvj4OLDkivezs7PF6C4d75eCXn8eYHEVYNvLatAY09HrL0C7oVQId8nKyuI+yJfZbOa+2X1PjHqBDrs3SMX99kvZHNWebr8ftrBr/9nXEJ5+HeHiGwiaWzuF2iRjYx80/yi09+K8/c7NzXEf5JN8+zFN//MR9utgXFwcsmXHyclJLvOWj7R+bcIbb32GlwsNeOVlAxa+/Tm2fWMSo/txz0cGBgZ41kg1ySTJRwj3DJFdCrG2thYrKipcMikgW5R1VldXu+zm5uZKlyE6IeMZGRnY1tbG21Lm7GTD3WZrayvqdDrpc3biyPyLQhyJ/7WcsMwN19fXRU96KF/39ET0xv//xv/TOCKBAPwNhuBpSQOkaKUAAAAASUVORK5CYII="
                  alt="KR" />
              </button>
              <div tabindex="-1" role="menu" aria-hidden="true" class="dropdown-menu"
                :class="{ show: showLanguageSelect }">
                <button @click="changeLanguage('en')" v-if="language !== 'en'" tabindex="0" role="menuitem"
                  class="dropdown-item">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAZCAYAAABzVH1EAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAU7SURBVFhH3ZhtUFRVHMafe9d1F5DFBE1D0TREslLDt7W+CCZfaEbJMTNB/VBmGi8S4EtDWCNvqwsipthMqVgzleWHdJocgQ+VSKnoOOO6OOloSYZCsAiyrMvtf87efeGyL5TijD4zd2bvPffuPb99zjn/56xgXrhMGr/PAM34sXBKkiTkl9fio4IfgHu9QFgQoBLlVj+y2THySR2a63Nx0/AJbmwpgipMx5vs7RZEbtuI0dnvYuTsYtxutgBqFW/zKzu9v/0uoFFj6/Y3kLd8Gm5sKsDN0n24Z23HsBmzMIH6L1qOn8DZCZNgmpuE7sYr/FlBELA1PR7S3waU7VgC3LUBrZ2OL31YYu9i7+zpxa6DayA1FSHt7GHUCyG4XrQVwdOfwzTzGUw9exwhM6dBnCndQmxWDiz1tWiImdIHiCl9lR7SX8Uo5UA9gw/kAVDOAP4swIraKg5g2lEM3Zz5mGE2IfbUUWgnT5QfAkThqY2omp+COVInAeXKQLH9gDI4UIkMNAgOKR0ggBQXQAl0cxnApX4AZfvrIIzKhgjtEKSlVkIYu9kDyOkQAem9ARU7hlzXA3DIBWB3Aayo8eJAnReAMbnIzDoMDNMQCJvEI0KAoaIMtAmHPIFO+RlyN+/DoV63A44hVOgeQkbfDuxkAKNzHABBakfficG9FLmAVHivn0OBhpzsEAG1tnfJLb7V2kb3+BpCARzIYADBQ10ATolooV/F82ijpU6iljt3kZZkhBCcDuMLyRwo8u10tNfX4EzMJFyIfgl3Tp52fAuJO3R7Oyo+TJKv+NbubYvIzY+x7Jty1NEK6W8SbzGegKBZj8zVBxyLDesb66Oi38LRWjNr8ik7DZd/aB3XhQVjcUIMerutsFT/xIoN7t1qQWj8y31qkFP+6ghTy4GvIepCIWo10EyMgjbmGX7dU98dv4iOO1Y8QXVMFaiOUfEbFDXvPSidVo+TGiKm8oN9ZtcGS0I9hvt15P9KpQ51ueEUc8Vu65DPHqwE9mvJnx9pBRh4j44eG5DHZo48PqvWQOtIaIgGyYnPylfd6jb/DuuVa7y+9Fo6EL5yKb8eqI54ynr9BjqoNg2JCGcxFjqqTWKQFkdqGmFp6xxQHRmSlFwpf1SIZSG2oaHNz8bCRSjMekVucKj78lVcTU1D26kf6cyG6NS1iNhfisrD57BmyXTHTT6058tfsXb5bPmM9kxRkbBNicbVlemwXP4FKgRhzFvrsJg2TPmHzmFVyh4KqFbHBk/0DiQinDKL5zGcbhaoRauG8bNUSD27+0AwAJP+VTRMnkKBsgaxmTnQU5X/6vVMykJ5WPfBEflO31qffwxCRBZKPz8pX6EAq4/D840/I45yXNjcBDR9Wk75KxgZtJmSOnei4lgWECr3jfVR0W83njNOUzQ3bn+NJ9vM1fPkRhlgnhOgmgCyef76YsFKCOM2Y33KXnJPxAiKMoE0YjjdQ8Fvw/vf8iTrCaSNfpoC4/eY0WiiBJwAU2kJD5RvnjgA6Y8CVFS9wwOnMm2LgQGuyA7EwFLHHGAAXQ4ASshOAGUaDSg2RNgzPoEmykBm6PTxBGTgDnEgSswcyOYGEkGhzKcDHCDW4cCGnP4O0B7mPwMoxZ71C0QOnWRAl2SHGJDCoW47RKnZ4AOAHOBzQHYgoe8Qum8ApQYCxB1iQMwhjyHXVOieI94msbc58MABlBowEA05DmTAb0IExM7T53ExLhHnJ7+IroYLiMrN4wC7Zi2FELnp4QEopQQalY28smr+nxuTEyjuGq1yiQvxL7n4dpFr8sWQAAAAAElFTkSuQmCC"
                    alt="EN" />
                  English
                </button>
                <button @click="changeLanguage('ko')" v-if="language !== 'ko'" tabindex="0" role="menuitem"
                  class="dropdown-item">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAhCAYAAACbffiEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAUZSURBVFhH7ZhdTNtVFMBPB4Vuo2Z+BFKy4XR0g82CG5QSCzFkGRAkEIzZEEgcAYQYX8z8WHyVkBAS6oMxoNEH5UsjBB40QDVOkYlAYdAQUhIDCoQEUoRB0bLC8Z7LbSmDftD+HxT9NTf33nNPz7mn997//57KkAFHgGOi/tfz3w0kJSUFbDab6EnPxsYG6HQ60TsEdEb8paCggM4TTk9PY319vZBKh8Fg4LbJB/k6DH4HMjg4yB2MjIygWq3m7ampKbRarUIjcJaXl9FisXCbZHt0dJS3jUaj0PCN34GQ4czMTOzo6OBth8PB69jYWKEROM4fxmmzvb0d09LSsKamRmj4xq9AcnJyuAOCatoC5eXlLpkUkK2ysjKsq6tz2aXtlZ6eztu+8DmTvr4+bnhiYgJjYmJQqVTi/Pw8l3V1dQmt/Sy196Dl5m0cSyvEMX0hWl55B5e+6haj++ns7HQFoFAoMDo6GoeGhriM5uALn4GQoby8PGxpaXE5opo9vXj7YRY++gJ/gBi8A2ewD9T4U0g8L9QmGY0tfPyl0N7LzMwMX332VOQ+yGd+fr7Lrzd8ahQXF+Ps7Cw31tDQgCUlJR4Nm6/dxO9Bhf3HE/BuxLMHFhojHfO1UvGtvZBt8kG+qE2+i4qKxKhnfL5HmpqaYHFxERISEkCr1fJ+d3e3GN3FUvImWI3fQXhEFMhCPJulMdKxGr8FS/EtId2lp6eH+yBfGo2G+25ubhajnjnUXauqqgqGh4d5ccc6PAFm7fMQdvI0yGQyIfUOubXb5kFrugMRVy4J6Q7JycmQlJQEjY2NQuIbSS6Nn2hLQHPPBFsKhZAcAHMjZyUEEBwsWAfIYNu+CSeeOQ+XRzuFUuAEfdfaYOXu8BSEyUN3BAcQitsQ5fgLfg2PgN5HVPB72Enel4fLYeVev9AKjqAD6R/9DSYfVYECt4RkL2HbW7B2TA4XL+VB4YVMuP1kKrwUlw1J8S/wlQlnn7XBcaEdOEEHYrOuws+Ralg/FgqKbQffQk4oCAVbjavxOWAPkQOwVYCtTYAHf8KK/DjoWTBKtsm2rH+IbwRO0IFEPhbBJrYJWjapBTY5FZvkKYcdolh9n00+mQUBtFosoD2QjAX/qfwcKE8phTBwJDnsMtWrAKefYIchHFQbyxBrX4MZdh5mTzzOVsHONDy4kIXAc2O/QL/dKASBE/SKEGrtOYDNB3zrLLCD3McO9CwLhG8lT0EQ7Kl15moAuccBHCoQeo/QM/5hmt67DrB0n82ZJs0KbSNfC03jS6vwbm25EOxCPiorK0XPP/wKhOUgkJiYyI2bTCb+9nUnJfEpKLieCrBCD2M/WbHBizdSIUFzVgh26O3t5T7oR6PbBPn2B5+BsHsPREZGwvj4OLDkivezs7PF6C4d75eCXn8eYHEVYNvLatAY09HrL0C7oVQId8nKyuI+yJfZbOa+2X1PjHqBDrs3SMX99kvZHNWebr8ftrBr/9nXEJ5+HeHiGwiaWzuF2iRjYx80/yi09+K8/c7NzXEf5JN8+zFN//MR9utgXFwcsmXHyclJLvOWj7R+bcIbb32GlwsNeOVlAxa+/Tm2fWMSo/txz0cGBgZ41kg1ySTJRwj3DJFdCrG2thYrKipcMikgW5R1VldXu+zm5uZKlyE6IeMZGRnY1tbG21Lm7GTD3WZrayvqdDrpc3biyPyLQhyJ/7WcsMwN19fXRU96KF/39ET0xv//xv/TOCKBAPwNhuBpSQOkaKUAAAAASUVORK5CYII="
                    alt="KR" />
                  한국어
                </button>
                <button @click="changeLanguage('vn')" v-if="language !== 'vn'" tabindex="0" role="menuitem"
                  class="dropdown-item">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAhCAYAAACbffiEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAUZSURBVFhH7ZhdTNtVFMBPB4Vuo2Z+BFKy4XR0g82CG5QSCzFkGRAkEIzZEEgcAYQYX8z8WHyVkBAS6oMxoNEH5UsjBB40QDVOkYlAYdAQUhIDCoQEUoRB0bLC8Z7LbSmDftD+HxT9NTf33nNPz7mn997//57KkAFHgGOi/tfz3w0kJSUFbDab6EnPxsYG6HQ60TsEdEb8paCggM4TTk9PY319vZBKh8Fg4LbJB/k6DH4HMjg4yB2MjIygWq3m7ampKbRarUIjcJaXl9FisXCbZHt0dJS3jUaj0PCN34GQ4czMTOzo6OBth8PB69jYWKEROM4fxmmzvb0d09LSsKamRmj4xq9AcnJyuAOCatoC5eXlLpkUkK2ysjKsq6tz2aXtlZ6eztu+8DmTvr4+bnhiYgJjYmJQqVTi/Pw8l3V1dQmt/Sy196Dl5m0cSyvEMX0hWl55B5e+6haj++ns7HQFoFAoMDo6GoeGhriM5uALn4GQoby8PGxpaXE5opo9vXj7YRY++gJ/gBi8A2ewD9T4U0g8L9QmGY0tfPyl0N7LzMwMX332VOQ+yGd+fr7Lrzd8ahQXF+Ps7Cw31tDQgCUlJR4Nm6/dxO9Bhf3HE/BuxLMHFhojHfO1UvGtvZBt8kG+qE2+i4qKxKhnfL5HmpqaYHFxERISEkCr1fJ+d3e3GN3FUvImWI3fQXhEFMhCPJulMdKxGr8FS/EtId2lp6eH+yBfGo2G+25ubhajnjnUXauqqgqGh4d5ccc6PAFm7fMQdvI0yGQyIfUOubXb5kFrugMRVy4J6Q7JycmQlJQEjY2NQuIbSS6Nn2hLQHPPBFsKhZAcAHMjZyUEEBwsWAfIYNu+CSeeOQ+XRzuFUuAEfdfaYOXu8BSEyUN3BAcQitsQ5fgLfg2PgN5HVPB72Enel4fLYeVev9AKjqAD6R/9DSYfVYECt4RkL2HbW7B2TA4XL+VB4YVMuP1kKrwUlw1J8S/wlQlnn7XBcaEdOEEHYrOuws+Ralg/FgqKbQffQk4oCAVbjavxOWAPkQOwVYCtTYAHf8KK/DjoWTBKtsm2rH+IbwRO0IFEPhbBJrYJWjapBTY5FZvkKYcdolh9n00+mQUBtFosoD2QjAX/qfwcKE8phTBwJDnsMtWrAKefYIchHFQbyxBrX4MZdh5mTzzOVsHONDy4kIXAc2O/QL/dKASBE/SKEGrtOYDNB3zrLLCD3McO9CwLhG8lT0EQ7Kl15moAuccBHCoQeo/QM/5hmt67DrB0n82ZJs0KbSNfC03jS6vwbm25EOxCPiorK0XPP/wKhOUgkJiYyI2bTCb+9nUnJfEpKLieCrBCD2M/WbHBizdSIUFzVgh26O3t5T7oR6PbBPn2B5+BsHsPREZGwvj4OLDkivezs7PF6C4d75eCXn8eYHEVYNvLatAY09HrL0C7oVQId8nKyuI+yJfZbOa+2X1PjHqBDrs3SMX99kvZHNWebr8ftrBr/9nXEJ5+HeHiGwiaWzuF2iRjYx80/yi09+K8/c7NzXEf5JN8+zFN//MR9utgXFwcsmXHyclJLvOWj7R+bcIbb32GlwsNeOVlAxa+/Tm2fWMSo/txz0cGBgZ41kg1ySTJRwj3DJFdCrG2thYrKipcMikgW5R1VldXu+zm5uZKlyE6IeMZGRnY1tbG21Lm7GTD3WZrayvqdDrpc3biyPyLQhyJ/7WcsMwN19fXRU96KF/39ET0xv//xv/TOCKBAPwNhuBpSQOkaKUAAAAASUVORK5CYII="
                    alt="VN" />
                  việt nam
                </button>
                <button @click="changeLanguage('th')" v-if="language !== 'th'" tabindex="0" role="menuitem"
                  class="dropdown-item">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAhCAYAAACbffiEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAUZSURBVFhH7ZhdTNtVFMBPB4Vuo2Z+BFKy4XR0g82CG5QSCzFkGRAkEIzZEEgcAYQYX8z8WHyVkBAS6oMxoNEH5UsjBB40QDVOkYlAYdAQUhIDCoQEUoRB0bLC8Z7LbSmDftD+HxT9NTf33nNPz7mn997//57KkAFHgGOi/tfz3w0kJSUFbDab6EnPxsYG6HQ60TsEdEb8paCggM4TTk9PY319vZBKh8Fg4LbJB/k6DH4HMjg4yB2MjIygWq3m7ampKbRarUIjcJaXl9FisXCbZHt0dJS3jUaj0PCN34GQ4czMTOzo6OBth8PB69jYWKEROM4fxmmzvb0d09LSsKamRmj4xq9AcnJyuAOCatoC5eXlLpkUkK2ysjKsq6tz2aXtlZ6eztu+8DmTvr4+bnhiYgJjYmJQqVTi/Pw8l3V1dQmt/Sy196Dl5m0cSyvEMX0hWl55B5e+6haj++ns7HQFoFAoMDo6GoeGhriM5uALn4GQoby8PGxpaXE5opo9vXj7YRY++gJ/gBi8A2ewD9T4U0g8L9QmGY0tfPyl0N7LzMwMX332VOQ+yGd+fr7Lrzd8ahQXF+Ps7Cw31tDQgCUlJR4Nm6/dxO9Bhf3HE/BuxLMHFhojHfO1UvGtvZBt8kG+qE2+i4qKxKhnfL5HmpqaYHFxERISEkCr1fJ+d3e3GN3FUvImWI3fQXhEFMhCPJulMdKxGr8FS/EtId2lp6eH+yBfGo2G+25ubhajnjnUXauqqgqGh4d5ccc6PAFm7fMQdvI0yGQyIfUOubXb5kFrugMRVy4J6Q7JycmQlJQEjY2NQuIbSS6Nn2hLQHPPBFsKhZAcAHMjZyUEEBwsWAfIYNu+CSeeOQ+XRzuFUuAEfdfaYOXu8BSEyUN3BAcQitsQ5fgLfg2PgN5HVPB72Enel4fLYeVev9AKjqAD6R/9DSYfVYECt4RkL2HbW7B2TA4XL+VB4YVMuP1kKrwUlw1J8S/wlQlnn7XBcaEdOEEHYrOuws+Ralg/FgqKbQffQk4oCAVbjavxOWAPkQOwVYCtTYAHf8KK/DjoWTBKtsm2rH+IbwRO0IFEPhbBJrYJWjapBTY5FZvkKYcdolh9n00+mQUBtFosoD2QjAX/qfwcKE8phTBwJDnsMtWrAKefYIchHFQbyxBrX4MZdh5mTzzOVsHONDy4kIXAc2O/QL/dKASBE/SKEGrtOYDNB3zrLLCD3McO9CwLhG8lT0EQ7Kl15moAuccBHCoQeo/QM/5hmt67DrB0n82ZJs0KbSNfC03jS6vwbm25EOxCPiorK0XPP/wKhOUgkJiYyI2bTCb+9nUnJfEpKLieCrBCD2M/WbHBizdSIUFzVgh26O3t5T7oR6PbBPn2B5+BsHsPREZGwvj4OLDkivezs7PF6C4d75eCXn8eYHEVYNvLatAY09HrL0C7oVQId8nKyuI+yJfZbOa+2X1PjHqBDrs3SMX99kvZHNWebr8ftrBr/9nXEJ5+HeHiGwiaWzuF2iRjYx80/yi09+K8/c7NzXEf5JN8+zFN//MR9utgXFwcsmXHyclJLvOWj7R+bcIbb32GlwsNeOVlAxa+/Tm2fWMSo/txz0cGBgZ41kg1ySTJRwj3DJFdCrG2thYrKipcMikgW5R1VldXu+zm5uZKlyE6IeMZGRnY1tbG21Lm7GTD3WZrayvqdDrpc3biyPyLQhyJ/7WcsMwN19fXRU96KF/39ET0xv//xv/TOCKBAPwNhuBpSQOkaKUAAAAASUVORK5CYII="
                    alt="TH" />
                  việt nam
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="LoginModal" tabindex="-1" role="dialog" aria-labelledby="LoginModal" aria-hidden="true"
      style="display: none"></div>
  </header>
</template>

<style scoped>
.dropdown-menu.show {
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(-32px, 24px, 0px);
}

.dropdown-item {
  cursor: pointer;
}
</style>
