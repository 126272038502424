// 自定义指令，用于处理点击外部区域的事件
const clickOutside = (el, binding) => {
    el.clickOutsideEvent = function (event) {
        if (!(el === event.target || el.contains(event.target))) {
            binding.value(event);
        }
    };
    document.addEventListener("click", el.clickOutsideEvent);
};

export { clickOutside }
